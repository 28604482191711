<!-- body container start-->
<div class="container-fluid">
    <div class="pl-3 pr-3">

        <!-- breadcrumb and heading start-->
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent pl-0 pr-0 mb-0 pb-0 pt-4">
                <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">Edit Notification</li>
            </ol>
        </nav>
        <div class="row">
            <div class="col-md-6">
                <h1 class="heading-1">Edit Notification</h1>
            </div>
            <div class="col-md-6  pt-2 text-right"><a href="#" routerLink="/notifications"
                    class="btn btn-woutline-secondary "><i class="fa fa-angle-left" aria-hidden="true"></i> &nbsp; BACK
                    TO NOTIFICATIONS</a> &nbsp; <a (click)="publishNotification(notification)"
                    class="btn btn-woutline-primary" *ngIf="published == false">PUBLISH</a></div>
        </div>
        <!-- breadcrumb and heading end-->


        <!-- pagecontent start-->
        <div class="row ">
            <div class="col-md-7">
                <div class="card ">
                    <div class="card-body ">
                        <form #notification="ngForm" (ngSubmit)="updateNotification(notification)" ngNativeValidate>
                            <div class="form-group mb-4">
                                <label>Sent to</label>
                                <ng-multiselect-dropdown [placeholder]="'custom placeholder'" name="sent_to"
                                    [data]="all_users" [(ngModel)]="sent_to" [settings]="dropdownSettings"
                                    (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                                    (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onDeSelectAll($event)"
                                    required [ngClass]='requiredClass'>
                                </ng-multiselect-dropdown>

                            </div>

                            <div class="form-group mb-4">
                                <label>Subject</label>
                                <input type="text" placeholder="Enter Subject" name="subject"
                                    [ngModel]="notificationResult?.details.subject" class="form-control" maxlength="500"
                                    class="form-control" required>
                                <div class="text-right f-sm help-block">Max 500 charecters</div>
                            </div>

                            <div class="form-group mb-4">
                                <label>Description</label>
                                <textarea rows="5" class="form-control" placeholder="Enter description"
                                    name="description" [ngModel]="notificationResult?.details.description"
                                    maxlength="2000" required></textarea>
                                <div class="text-right f-sm help-block">Max 2,000 charecters</div>
                            </div>


                            <div class="form-group mb-4">
                                <label>Media</label>
                                <div class="clearfix"></div>
                                <div class="upload-file text-center">
                                    <span><i class="fa fa-upload" aria-hidden="true"></i> Upload File</span>
                                    <input id="inputIncludeFile" type="file" name="media"
                                        (change)="onFileSelected($event)" [ngModel]="notificationResult?.details.image"
                                        placeholder="Inlcude some file">
                                </div>

                                <div class="form-field d-inline-block" *ngIf="notificationResult?.details.image != ''">
                                    <a class="close action-top-right" href="javascript:void(0)"><i
                                            class="fa fa-times-circle" aria-hidden="true"></i> </a>
                                    <img src="{{notificationResult?.details.image}}" width="100px" alt=""></div>

                            </div>

                            <div class="form-group mb-4">
                                <label>Navigate to</label>
                                <select class="form-control">
                                    <option value="notifications">All Notifications</option>
                                </select>
                            </div>


                            <div class="form-group mb-4">
                                <label>Interval</label>
                                <select class="form-control" name="interval" [ngModel]="interval" (change)="selectedInterval($event.target.value)">
                                    <option value="once">One Time</option>
                                    <option value="daily">Daily</option>
                                    <option value="weekly">Weekly</option>
                                    <option value="monthly">Monthly</option>
                                </select>
                            </div>


                            <div class="form-group mb-4">
                                <label>Schedule</label> 
                                <div class="form-group">
                                    <div class='input-group date' id='datetimepicker2'>
                                        <input [owlDateTime]="dt1" class="form-control" name="schedule_date"
                                            *ngIf="showdate == true" [ngModel]="notificationResult?.schedule?.date"
                                            [owlDateTimeTrigger]="dt1" [selectMode]="'single'" placeholder="Select Date"
                                            required>
                                        <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>&nbsp;&nbsp;&nbsp;

                                        <!-- <span class="input-group-addon">
                                            <i class="fa fa-calendar mt-2" aria-hidden="true"></i>
                                        </span>&nbsp;&nbsp;&nbsp; -->
                                      

                                        <input [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2" name="schedule_time"
                                            class="form-control" [ngModel]="notificationResult?.schedule?.time" placeholder="Select Time" required>
                                        <owl-date-time [pickerType]="'timer'" [stepMinute]="30" #dt2></owl-date-time>&nbsp;&nbsp;&nbsp;


                                        <select class="form-control" *ngIf="showweekday == true" name="weekday"
                                            [(ngModel)]="weekday">
                                            <option value="Sunday">Sunday
                                            </option>
                                            <option value="Monday">Monday
                                            </option>
                                            <option value="Tuesday">Tuesday
                                            </option>
                                            <option value="Wednesday">
                                                Wednesday
                                            </option>
                                            <option value="Thursday">
                                                Thursday
                                            </option>
                                            <option value="Friday">Friday
                                            </option>
                                            <option value="Saturday">
                                                Saturday
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="clearfix"></div>
                            <div class=" row mt-4">
                                <div class="col"> <a class="btn btn-outline-danger" href="javascript:void(0)"
                                        (click)="deleteNotification(currentURLId)"><i class="fa fa-trash-o"></i>
                                        DELETE</a></div>
                                <div class="col text-right">
                                    <button class="btn btn-outline-primary  " type="submit">SAVE</button>
                                    &nbsp;&nbsp;
                                    <a class="btn btn-outline-primary  " *ngIf="published == false" (click)="publishNotification(notification)"
                                        type="submit">Publish</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- preview start-->
            <div class="col-md-5">
                <div class="preview">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-8"><i class="fa fa-envelope text-primary"
                                        aria-hidden="true"></i>&nbsp;&nbsp; Notifications </div>
                                <div class="col-4 text-right"><span
                                        class="text-muted f-sm">{{notificationResult?.date | date :'dd-MMM-yy h:mm:ss a'}}</span>
                                </div>
                            </div>

                            <div class="media pt-3">

                                <img src="{{notificationResult?.details.image}}"
                                    *ngIf="notificationResult?.details.image != '';else newImage" width="41" height="41"
                                    class="mr-3" alt="" style="border-radius: 100%">
                                <ng-template #newImage><img src="assets/images/thumb-emailer.png" class="mr-3" alt="">
                                </ng-template>

                                <div class="media-body">
                                    <h5 class="mt-0 heading-5">{{notificationResult?.details.subject}}</h5>
                                    {{notificationResult?.details.description}}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- preview end-->

        </div>

        <!-- pagecontent end-->


    </div>

    <!-- footer start-->
    <div id="footer">
        <div class="container-fluid">
            <p class="copyright">&copy; shopimob 2020. All Rights Reserved</p>
        </div>
    </div>
    <!-- footer end-->


</div>

<div class="loading" *ngIf="isLoading == true">Loading</div>
<!-- body container end-->