<!-- body container start-->
<div class="container-fluid">
    <div class="pl-3 pr-3">
        <!-- breadcrumb and heading start-->
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent pl-0 pr-0 mb-0 pb-0 pt-4">
                <li class="breadcrumb-item active" aria-current="page"><a routerLink="/dashboard">Dashboard</a></li>
            </ol>
        </nav>
        <div class="row">
            <div class="col-md-8">
                <h1 class="heading-1">Dashboard</h1>
            </div>
           
        </div>
        <!-- breadcrumb and heading end-->
        <!-- pagecontent start-->
        <div class="row ">
            <div class="col-md-12">
                <div class="card " id="bg">
                    <div class="card-header bg-transparent border-0">
                        <div class="row pt-5 pb-5">
                            <div class="col-md-6 pt-5" *ngIf =" appStatus == undefined" >
                                <h3 class="text-white pl-2 pr-2 mt-4">Welcome to Shopimob ...!!</h3>
                            </div>
                            <div class="col-md-6 pt-5" *ngIf = "appStatus == 'Inprogress'" >
                                <h3 class="text-white pl-2 pr-2 mt-4">
                                    <div>Your App is getting Created...!!</div>
                                    <div style="font-size:15px; margin-top:10px;">Please be patience while we create your app.</div><div style="font-size:15px; margin-top:10px;">We will get back to you soon..</div>
                                </h3>
                            </div>

                            <div class="col-md-6 pt-5" *ngIf = "appStatus == 'Active'" >
                                <h3 class="text-white pl-2 pr-2 mt-4">Welcome to Shopimob ...!!</h3>
                            </div>
                            
                            <div class="col-md-6 text-center"><img src="assets/images/img-progress.png"
                                    class="img-fluid"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row ">
            <div class="col-md-8">
                <div class="card border-0 bg-transparent rounded-lg">
                    <div class="card-body  p-0">
                        <div class=" text-center ">
                            <iframe width="660" height="300" src="https://www.youtube.com/embed/t2SGXCC8sEM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-md-4">
                <div class="card  ">
                    <div class="card-header bg-transparent border-0">
                        <div class="row pt-3">
                            <div class="col-md-12">
                                <h3 class="heading-3">Support</h3>
                                <p class="pt-2">Do you need support, connect with
                                    us via phone and email.</p>
                            </div>
                        </div>
                    </div>

                    <div class="card-body pt-0 pb-3 mt-2 mb-5  ">
                        <div class="flex"><a href="javascript:void(0)"><span
                                    class="bg-blue-200 rounded-circle d-inline-block icon-sm mb-3 mr-2"><i
                                        class="fa fa-skype" aria-hidden="true"></i></span> live:webuters.tech </a></div>
                        <div class="flex"><a href="mailto:sales@webuters.com"><span
                                    class="bg-blue-200 rounded-circle d-inline-block  icon-sm mb-2 mr-2 "><i
                                        class="fa fa-envelope" aria-hidden="true"></i></span>
                                <span>sales@webuters.com</span></a></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- pagecontent end-->
    </div>
    <!-- footer start-->
    <div id="footer">
        <div class="container-fluid">
            <p class="copyright">&copy; shopimob 2020. All Rights Reserved</p>
        </div>
    </div>
    <!-- footer end-->
</div>

<!-- chat icon -->
<!-- <div class="fixed-bottom    d-inline-block" style="z-index:1000"><a href="javascript:void(0)"
        class="bg-pink icon-lg d-block pull-right mr-5 mb-5 rounded-circle shadow-sm"><i class="fa fa-comments"
            aria-hidden="true"></i></a></div> -->
<!-- chat icon -->
<!-- body container end-->
<!-- 

    </div>


</div> -->