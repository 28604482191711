import { Component, OnInit } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  userInfo:any;
  lastLogin:string;
  shopInfo:any;
  appStatus;
  appType;
  constructor(private location: LocationStrategy,public afAuth: AngularFireAuth,public router: Router) {

    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.shopInfo = JSON.parse(sessionStorage.getItem('shopInfo'));
    if (this.userInfo != null) {
      history.pushState(null, null, window.location.href);
      this.location.onPopState(() => { history.pushState(null, null, window.location.href) });
    } else {
      this.router.navigate(['/']);
    }
   }

  async ngOnInit() {
    let timestamp = Number(this.userInfo['lastLoginAt']);
    var time = new Date(timestamp).toISOString();
    this.lastLogin = time;
    this.appStatus = this.shopInfo['appStatus'];
    this.appType = this.shopInfo['appType'];

  }

}
