<!-- body container start-->
<div class="container-fluid">
    <div class="pl-3 pr-3">

        <!-- breadcrumb and heading start-->
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent pl-0 pr-0 mb-0 pb-0 pt-4">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">Banners</li>
            </ol>
        </nav>
        <div class="row">
            <div class="col-md-6">
                <h1 class="heading-1">Banners</h1>
            </div>
            <div class="col-md-6  pt-2 text-right"> <button href="#" (click)="onSubmit()"
                    class="btn btn-woutline-primary">CHANGE BANNER</button></div>
        </div>
        <!-- breadcrumb and heading end-->


        <!-- pagecontent start-->
        <div class="row ">
            <div class="col-md-7">
                <form class="p-2" (ngSubmit)="onSubmit()" ngNativeValidate>
                    <div class="card ">
                        <div class="card-body ">

                            <div class="form-group  row">
                                <h6 class=" col-12"><span class="pt-2 pull-left"> Show banner in app </span><label
                                        class="switch ml-2"> <input type="checkbox" name="show"
                                            value="{{show_banner_in_app}}" id="show" (click)="showBanner($event)">
                                        <span class="slider round"></span></label> </h6>

                            </div>



                            <div class="form-group mb-4">
                                <label> Banner 1 </label>

                                <div class="upload-file text-center" *ngIf="themeDetails?.banners.length == 0">
                                    <span><i class="fa fa-upload" aria-hidden="true"></i> Upload
                                        File</span>
                                    <input id="inputIncludeFile" type="file" placeholder="Inlcude some file"
                                        (change)="onBannerSelected($event)">
                                </div>

                                <div class="form-controls d preview-img" *ngIf="themeDetails?.banners.length >= 1">
                                    <div class="position-absolute tl-10">
                                        <a class="heading-1 mr-2"><i class="fa fa-check-circle"
                                                aria-hidden="true"></i></a>
                                        <!-- <a class="heading-1 link-white mr-2"><i class="fa fa-check-circle" aria-hidden="true"></i></a> -->
                                    </div>


                                    <div class="position-absolute tr-10">
                                        <a href="javascript:void(0)" class="heading-2 link-white mr-2"
                                            (click)="deleteBanner(0)"><i class="fa fa-times-circle"
                                                aria-hidden="true"></i></a>
                                    </div>
                                    <img src="{{ themeDetails?.banners[0]  }}" class="img-fluid w-100">
                                </div>
                            </div>

                            <div class="form-group mb-4">
                                <label>Banner 2</label>
                                <div class="upload-file text-center" *ngIf="themeDetails?.banners.length < 2">
                                    <span><i class="fa fa-upload" aria-hidden="true"></i> Upload File</span>
                                    <input id="inputIncludeFile" type="file" placeholder="Inlcude some file"
                                        (change)="onBannerSelected($event)">
                                </div>
                                <div class="form-controls d preview-img" *ngIf="themeDetails?.banners.length >= 2">
                                    <div class="position-absolute tl-10">
                                        <a class="heading-1 mr-2"><i class="fa fa-check-circle"
                                                aria-hidden="true"></i></a>
                                        <!-- <a class="heading-1 link-white mr-2"><i class="fa fa-check-circle" aria-hidden="true"></i></a> -->
                                    </div>


                                    <div class="position-absolute tr-10">
                                        <a href="javascript:void(0)" class="heading-2 link-white mr-2"
                                            (click)="deleteBanner(1)"><i class="fa fa-times-circle"
                                                aria-hidden="true"></i></a>
                                    </div>
                                    <img src="{{ themeDetails?.banners[1] }}" width="50px" class="img-fluid w-100">
                                </div>

                                <!-- <ng-template #secondBanner>
                                <div class="form-controls d preview-img" *ngIf="themeDetails?.banners.length >= 2">
                                    <div class="position-absolute tl-10">
                                        <a class="heading-1 mr-2"><i class="fa fa-check-circle" aria-hidden="true"></i></a>
                                        <a class="heading-1 link-white mr-2"><i class="fa fa-check-circle" aria-hidden="true"></i></a>
                                    </div>

                                    
                                    <div class="position-absolute tr-10">
                                        <a class="heading-2 link-white mr-2" (click)="deleteBanner(1)"><i class="fa fa-times-circle" aria-hidden="true"></i></a>
                                        </div>
                                     <img src="{{ themeDetails?.banners[1] }}"   class="img-fluid w-100">
                                </div>
                            </ng-template> -->

                            </div>


                            <div class="form-group mb-4">
                                <label>Banner 3</label>
                                <div class="upload-file text-center" *ngIf="themeDetails?.banners.length < 3">
                                    <span><i class="fa fa-upload" aria-hidden="true"></i> Upload File</span>
                                    <input id="inputIncludeFile" type="file" placeholder="Inlcude some file"
                                        (change)="onBannerSelected($event)">
                                </div>


                                <div class="form-controls d preview-img" *ngIf="themeDetails?.banners.length >= 3">
                                    <div class="position-absolute tl-10">
                                        <a class="heading-1 mr-2"><i class="fa fa-check-circle"
                                                aria-hidden="true"></i></a>
                                        <!-- <a class="heading-1 link-white mr-2"><i class="fa fa-check-circle" aria-hidden="true"></i></a> -->
                                    </div>
                                    <div class="position-absolute tr-10">
                                        <a href="javascript:void(0)" class="heading-2 link-white mr-2"
                                            (click)="deleteBanner(2)"><i class="fa fa-times-circle"
                                                aria-hidden="true"></i></a>
                                    </div>
                                    <img src="{{ themeDetails?.banners[2] }}" class="img-fluid w-100">
                                </div>

                                <!-- <ng-template #thirdBanner> 
                                <div class="form-controls d preview-img" *ngIf="themeDetails?.banners.length >= 3">
                                    <div class="position-absolute tl-10">
                                        <a class="heading-1 mr-2"><i class="fa fa-check-circle" aria-hidden="true"></i></a>
                                        <a class="heading-1 link-white mr-2"><i class="fa fa-check-circle" aria-hidden="true"></i></a>
                                    </div>

                                    
                                    <div class="position-absolute tr-10">
                                        <a class="heading-2 link-white mr-2" (click)="deleteBanner(2)"><i class="fa fa-times-circle" aria-hidden="true"></i></a>
                                        </div>
                                    <img src="{{ themeDetails?.banners[2]  }}"  class="img-fluid w-100">
                                </div>
                            </ng-template> -->


                            </div>




                        </div>
                    </div>


                    <div class="clearfix"></div>

                    <button type="submit" class="btn btn-outline-primary pull-right" href="">CHANGE BANNER</button>
                    <!-- <button type="submit" class="btn btn-outline-primary pull-right" href="">Published</button>>&nbsp;&nbsp;&nbsp;&nbsp; -->
                </form>
            </div>
            <!-- preview start-->
            <div class="col-md-5">
                <div>
                    <div class="carousel slide preview">

                        <div class="carousel-inner">

                            <div class="">
                                <div class="preview-container"
                                    [ngStyle]="{'background-color': themeDetails.body != undefined ? themeDetails?.body.color : '#029246' }">
                                    <div class="row">
                                        <div class="col-md-12 text-left pt-2" style="color:#fff;"> <i
                                                class="fa fa-bars ml-3 mt-2" aria-hidden="true"></i></div>
                                        <div class="col-md-12 mt-2" style="background-color:#fff; ">
                                            <div class="text-center pt-2" id="carouselDiv">
                                                <div id="carouselPreview" class="carousel slide" data-ride="carousel">
                                                    <div class="carousel-inner">
                                                        <div class="carousel-item active">
                                                            <img src="{{themeDetails?.banners[0]}}"
                                                                class="d-block w-100">
                                                        </div>
                                                        <div class="carousel-item">
                                                            <img src="{{themeDetails?.banners[1]}}"
                                                                class="d-block w-100">
                                                        </div>
                                                        <div class="carousel-item">
                                                            <img src="{{themeDetails?.banners[2]}}"
                                                                class="d-block w-100">
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div class="pl-3 pr-3 pt-3 pb-3" style="background-color:#fff;">
                                                <div class="row">
                                                    <div class="col-8"><span
                                                            style="color:#333; font-size:12px; font-weight:bold;">Exclusive
                                                            Offer</span> </div>
                                                    <div class="col-4 text-right"><span
                                                            style="font-size:11px; color:#666;">See all</span></div>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-6 pr-1">
                                                        <div class="border rounded p-2"><img
                                                                src="assets/images/preview/img-banana.png">
                                                            <span
                                                                style="color:#333; font-size:11px; display:block; font-weight:bold;">Organic
                                                                Banana</span> <span
                                                                style="color:#666; font-size:10px; display:block; ">7pcs,
                                                                Pricing</span>
                                                            <div class="row mt-2">
                                                                <div class="col-12"> <span
                                                                        style="color:#333; font-size:11px;">$4.00</span>
                                                                </div>
                                                                <div class="col-12"><button type="button" class="btn"
                                                                        style="background-color:#029246; color:#fff;">+</button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-6 pl-1">
                                                        <div class="border rounded p-2"><img
                                                                src="assets/images/preview/img-apple.png">
                                                            <span
                                                                style="color:#333; font-size:11px; display:block; font-weight:bold;">Red
                                                                Apple</span> <span
                                                                style="color:#666; font-size:10px; display:block; ">1Kg,
                                                                Pricing</span>
                                                            <div class="row mt-4">
                                                                <div class="col-12"> <span
                                                                        style="color:#333; font-size:11px;">$4.00</span>
                                                                </div>
                                                                <div class="col-12"><button type="button" class="btn"
                                                                        style="background-color:#029246; color:#fff;">+</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="col-8"><span
                                                            style="color:#333; font-size:12px; font-weight:bold;">Best
                                                            Selling</span> </div>
                                                    <div class="col-4 text-right"><span
                                                            style="font-size:11px; color:#666;">See all</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- preview end-->
        </div>

        <!-- pagecontent end-->


    </div>

    <!-- footer start-->
    <div id="footer">
        <div class="container-fluid">
            <p class="copyright">&copy; shopimob 2020. All Rights Reserved</p>
        </div>
    </div>
    <!-- footer end-->


</div>
<!-- body container end-->

<div class="loading" *ngIf="isLoading == true">Loading</div>