<!-- body container start-->
<div class="container-fluid">
    <div class="pl-3 pr-3">

        <!-- breadcrumb and heading start-->
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent pl-0 pr-0 mb-0 pb-0 pt-4">
                <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">Profile</li>
            </ol>
        </nav>
        <div class="row">
            <div class="col-md-12">
                <h1 class="heading-1">Profile</h1>
            </div>

        </div>
        <!-- breadcrumb and heading end-->


        <!-- pagecontent start-->
        <div class="row ">

            <!-- photo editing start-->
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <div class="avatar-upload">
                                    <div class="avatar-edit">
                                        <input type='file' id="imageUpload" (change)="fileChangeEvent($event)"  accept=".png, .jpg, .jpeg" />
                                        <label for="imageUpload"></label>
                                    </div>
                                    <div class="avatar-preview">
                                        <div id="imagePreview">
                                            <img src="{{details?.profile_pic}}"  onerror="this.src='assets/images/userpic.jpg'" style="border-radius: 60%;
                                            margin-left: -4px;
                                            height: 123px;
                                            margin-top: 0px;
                                            width: 123px;">
                                        </div>
                                    </div>
                                </div>

                                <h2 class="heading-4">{{details['first_name']}} {{details['last_name']}} </h2>
                                <!-- <p class="f-sm">Varanasi Up, India</p> -->
                            </div>
                        </div>
                    </div>
                    <div class="bg-light f-sm p-4">
                        <div class="row ">
                            <div class="col-md-4"><label>Email</label></div>
                            <div class="col-md-8">
                                <div class="form-group">{{email}}</div>
                            </div>
                        </div>

                        <div class="row ">
                            <div class="col-md-4"><label>Mobile</label></div>
                            <div class="col-md-8">
                                <div class="form-group">{{details['phone_number']}}</div>
                            </div>
                        </div>

                        <div class="row ">
                            <div class="col-md-4"><label>Birthday</label></div>
                            <div class="col-md-8">
                                <div class="form-group">{{ details['date_of_birth'] | date: 'dd/MM/yyyy' }}</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- photo editing end-->

            <div class="col-md-8">
                <div class="card ">
                    <ul class="nav nav-pills nav-primary" id="pills-tab" role="tablist">
                        <li class="nav-item " role="profile">
                            <a class="nav-link active" id="pills-profile-tab" data-toggle="pill" href="#pills-profile"
                                role="tab" aria-controls="pills-profile" aria-selected="true">Profile Settings</a>
                        </li>
                        <li class="nav-item " role="profile">
                            <a class="nav-link" id="pills-password-tab" data-toggle="pill" href="#pills-password"
                                role="tab" aria-controls="pills-password" aria-selected="false">Change Passoword</a>
                        </li>

                    </ul>

                    <div class="card-body ">
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-profile" role="tabpanel"
                                aria-labelledby="pills-profile-tab">
                                <form class="form" #profile="ngForm" (ngSubmit)="addProfileDetails(profile)"
                                    ngNativeValidate>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>First Name</label>
                                                <input type="text" placeholder="Enter first name" name="first_name" [ngModel]="details['first_name']"
                                                     class="form-control" required>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Last Name</label>
                                                <input type="text" placeholder="Enter last name" name="last_name"
                                                   class="form-control" [ngModel]="details['last_name']" required>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Gender</label>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="gender"
                                                                id="genderRadios1" value="male" [ngModel]="details['gender']"
                                                                required>
                                                            <label class="form-check-label" for="genderRadios1">
                                                                Male
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="gender" [ngModel]="details['gender']"
                                                                id="genderRadios2" value="female">
.                                                               
                                                            <label class="form-check-label" for="genderRadios2">
                                                                Female
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Birthday</label>
                                                <div class='input-group date' id='datetimepicker2'>
                                                    <input [owlDateTime]="dt1" class="form-control" name="date_of_birth"
                                                    [(ngModel)]="details['date_of_birth']" [owlDateTimeTrigger]="dt1"
                                                    placeholder="Date Time" required>
                                                <owl-date-time #dt1   pickerType="calendar"></owl-date-time>
                                                <span class="input-group-addon">
                                                    <i class="fa fa-calendar mt-2" aria-hidden="true"></i>
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Email</label>
                                                <input type="text" placeholder="Enter email" name="email"
                                                     class="form-control" [ngModel]="email" readonly required>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Mobile</label>
                                                <input type="text" placeholder="10 digit mobile number"
                                                    name="phone_number" class="form-control" [ngModel]="details['phone_number']" maxlength="10" required>
                                            </div>
                                        </div>

                                    </div>


                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Address</label>
                                                <textarea rows="5" class="form-control" name="address" [ngModel]="details['address']"
                                                   
                                                    placeholder="Enter address" required></textarea>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="clearfix"></div>
                                    <button type="submit" class="btn btn-outline-primary pull-right" href="">Change
                                        Profile
                                    </button>
                                </form>
                            </div>
                            <div class="tab-pane fade" id="pills-password" role="tabpanel"
                                aria-labelledby="pills-password-tab">
                                <div class="alert alert-success alert-dismissible fade show mt-4 mb-4" role="alert"
                                    *ngIf="successMsg != ''">{{successMsg}}

                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button></div>

                                <form>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Email</label>
                                                <input type="text" placeholder="Enter email" name="email"
                                                    [ngModel]="userInfo['email']" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>User Name</label>
                                                <input type="text" placeholder="Enter username" name="username"
                                                    [ngModel]="userInfo['displayName']" class="form-control">
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Password</label>
                                                <input type="password" placeholder="Enter password" name="password"
                                                    #password ngModel class="form-control">
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Confirm Password</label>
                                                <input type="password" placeholder="Enter password"
                                                    name="confirm_password" #confrmPassword ngModel
                                                    class="form-control">
                                            </div>
                                            <span class="error-msg" *ngIf="errorMsg != ''">{{errorMsg}}</span>

                                        </div>

                                    </div>


                                    <div class="clearfix"></div>
                                    <a type="submit" class="btn btn-outline-primary pull-right"
                                        (click)="passwordChange(password.value,confrmPassword.value)">Change
                                        Password</a>
                                </form>

                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>
        <!-- pagecontent end-->
    </div>

    <!-- footer start-->
    <div id="footer">
        <div class="container-fluid">
            <p class="copyright">&copy; shopimob 2020. All Rights Reserved</p>
        </div>
    </div>
    <!-- footer end-->
</div>
<div class="loading" *ngIf="isLoading == true">Loading</div>
<!-- body container end-->

<!-- Modal -->

<div class="modal fade" id="ImageModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modal-heading">
                    <h3 class="mt-4 mb-4">Crop Image</h3>
                </div>
                <div class="loading" *ngIf="isLoadingImage == true">Loading&#8230;</div>
                <image-cropper class="cropper" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                    [aspectRatio]="4/3" [resizeToWidth]="128" format="png" (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()"    [roundCropper]="true" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
                </image-cropper>
            </div>
            <button class="submit btn btn-primary" (click)="cropIt()">Done</button>

        </div>
    </div>
</div>