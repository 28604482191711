<!-- body container start-->
<div class="container-fluid">
    <div class="pl-3 pr-3">

        <!-- breadcrumb and heading start-->
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent pl-0 pr-0 mb-0 pb-0 pt-4">
                <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">Notification</li>
            </ol>
        </nav>
        <div class="row">
            <div class="col-md-6">
                <h1 class="heading-1">Notification</h1>
            </div>
            <div class="col-md-6  pt-2 text-right"><a href="#" routerLink="/new-notifications"
                    class="btn btn-woutline-primary"><i class="fa fa-plus" aria-hidden="true"></i> &nbsp; NEW
                    NOTIFICATION</a></div>
        </div>
        <!-- breadcrumb and heading end-->


        <!-- pagecontent start-->
        <div class="row ">
            <div class="col-md-12">
                <div class="card ">
                    <div class="card-header bg-transparent border-0">
                        <div class="row pt-3">
                            <div class="col-md-8">
                                <h3 class="heading-3">All Notifications</h3>
                            </div>
                            <div class="col-md-4"></div>
                        </div>
                    </div>
                  
                    <div class="card-body ">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <!-- <th>Sent</th> -->
                                        <th>ScheduleTo</th>
                                        <th>View</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let list of tableData;let i=index">
                                        <td>{{i+1}}</td>
                                        <td>
                                            <div class="media">
                                                <img src="{{list?.details?.image}}"
                                                    *ngIf="list?.details?.image != '';else defaultImage" width="41"
                                                    height="41" class="mr-3" alt="" style="border-radius: 100%">
                                                <ng-template #defaultImage><img src="assets/images/thumb-emailer.png"
                                                        class="mr-3" alt=""></ng-template>

                                                <div class="media-body">
                                                    <h5 class="mt-0 heading-5">{{list?.details?.subject}}</h5>
                                                    {{list?.details?.description}}
                                                </div>
                                            </div>
                                        </td>
                                        <!-- <td class="f-sm">{{ list?.sent_to }}</td> -->
                                        <td>{{ list?.schedule['interval_type'] }} {{ scheduleTime(list?.schedule['time']['seconds']) }}</td>
                                        <td>{{ list?.sent_to.length }}</td>
                                        <td><span class="badge badge-pill badge-success p-2 pl-3 pr-3"
                                                *ngIf="list?.status == 'Success'">Success</span>
                                            <span class="badge badge-pill badge-danger p-2 pl-3 pr-3"
                                                *ngIf="list?.status == 'Completed'">Completed</span>
                                            <span class="badge badge-pill badge-warning p-2 pl-3 pr-3"
                                                *ngIf="list?.status == 'Pending'">Pending</span>
                                        </td>
                                        <td><a href="#" routerLink="/edit-notifications/{{list?.id}}"
                                                class="btn btn-primary">Reschedule</a>&nbsp;&nbsp;&nbsp;
                                              </td>
                                    </tr>
                                    <tr *ngIf="noNotification == true">
                                        <td colspan="6">
                                            No notifications found!
                                        </td>

                                    </tr>
                                </tbody>                               
                            </table>
                            <div class="text-center" *ngIf="pagination==true">
                                <button class="btn btn btn-info btn-sm float-left" (click)="prevPage()"  style="background-color: #240a59;"
                                    [disabled]="disable_prev || !(pagination_clicked_count>0)">Previous</button> <b>Page no: {{pagination_clicked_count+1}}</b>
                                <button class="btn btn btn-info btn-sm float-right" style="background-color: #240a59;" (click)="nextPage()" [disabled]="disable_next">Next</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <!-- pagecontent end-->


    </div>

    <!-- footer start-->
    <div id="footer">
        <div class="container-fluid">
            <p class="copyright">&copy; shopimob 2020. All Rights Reserved</p>
        </div>
    </div>
    <!-- footer end-->


</div>

<div class="loading" *ngIf="isLoading == true">Loading</div>
<!-- body container end-->