<div id="bg"></div>
<!--Header start here-->
<div class="container-small">
    <div class="d-flex  flex-md-row align-items-center  px-md-4 ">
        <h1 class="my-0 mr-md-auto font-weight-normal"><img src="assets/images/logo.png" /></h1>
        <a class="btn btn-menu" routerLink="/login">Login</a>
        <a class="btn btn-menu active" routerLink="/signup">Signup</a>
    </div>
</div>
<!--Header end here-->
<!--Body start here-->
<div class="container-small">
    <div class="page-form ">
        <div class="row">
            <div class="col-lg-5 col-md-6 text-center">
                <div class="img-intro text-center"><img src="assets/images/img-intro.png" class="img-fluid  " /></div>
            </div>
            <div class="col-lg-7 col-md-6">
                <form class="form" #login="ngForm" (ngSubmit)="register(login)" ngNativeValidate>
                    <div class="header-content">
                        <h2>Signup</h2>
                    </div>
                    <div class="alert alert-success alert-dismissible fade show mt-4 mb-4" role="alert"
                        *ngIf="successMsg == true">
                        Thank You for Registering!.We have sent a confirmation email.Please click on link to verify!
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button></div>

                    <div class="alert alert-danger alert-dismissible fade show mt-4 mb-4" role="alert"
                        *ngIf="errorMsg != ''">
                        {{errorMsg}} <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button></div>

                    <div class="body-content">
                        <div class="form-group mb-3">
                            <div class="input-icon left"><i class="fa fa-envelope"></i><input type="email"
                                    placeholder="Email" name="user_email" ngModel class="form-control" required>
                            </div>
                            <!-- <span class="error-msg">Email already exist</span> -->
                        </div>
                        <div class="form-group mb-3">
                            <div class="input-icon left"><i class="fa fa-user"></i><input type="text"
                                    placeholder="User Name" name="user_name" ngModel class="form-control" required>
                            </div>
                        </div>

                        <div class="form-group mb-3">
                            <div class="input-icon left"><i class="fa fa-lock"></i> <i class="fa fa-eye right"
                                    (click)="tooglePassword()"></i><input [type]="showPassword ? 'text' : 'password'"
                                    #passwrd placeholder="Password" name="password"
                                    (change)="comparePassword(passwrd.value,cnfrmpasswrd.value)" ngModel
                                    class="form-control" required>
                            </div>

                            <!-- <span class="error-msg" *ngIf="password.errors?.pattern" style="color:red">*Invalid
                                Password!</span> -->
                            <!-- <span class="error-msg">Password should be #@123abc</span> -->
                        </div>

                        <div class="form-group mb-3">
                            <div class="input-icon left"><i class="fa fa-lock"></i>
                                <i class="fa fa-eye right" (click)="toogleConfirmPassword()"></i><input
                                    [type]="showConfirmPassword ? 'text' : 'password'" #cnfrmpasswrd
                                    placeholder="Confirm Password" name="confirm_password"
                                    (change)="comparePassword(passwrd.value,cnfrmpasswrd.value)" ngModel
                                    class="form-control" required>
                            </div>
                            <span class="error-msg" *ngIf="passwordNotMatch == true ">{{matchingPassword}}</span><br />
                            <!-- <span class="error-msg">*minimum 8 character must contain at least one lowercase , one
                                uppercase letter, one numeric digit,and one special character</span><br /> -->
                            <!-- <span class="error-msg" *ngIf="confirm_password.errors?.pattern" style="color:red">*Invalid
                                Password!</span> -->

                        </div>


                        <div class="form-group pull-left pt-1 pb-3">
                            <div class="checkbox-list"><label><input type="checkbox" name="policy" ngModel
                                        required>&nbsp;
                                    I agree to the terms &amp; Privacy Policy</label></div>
                        </div>

                        <div class="clearfix"></div>
                        <button type="submit" class="btn btn-smob" [disabled]="submitButtonDisabled">Signup</button>


                        <p class="text-center pt-5 mb-0">Already have account? <a id="btn-register"
                                routerLink="/login">Login</a></p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<!--Footer start here-->
<footer class="footer  pt-5 pb-2  text-center">
    <div class="container-small">
        <p class="text-white"> &copy; shopimob 2020, All Rights Reserved</p>
    </div>
</footer>
<!--Footer end here-->

<div class="loading" *ngIf="isLoading == true">Loading</div>
<!--Body end here-->