  <!-- sidebar start-->
  <nav id="sidebar" style="min-height: 100%;">
    <ul class="list-unstyled components">
        <li class="active">
            <a routerLink="/dashboard"><i class="fa fa-tachometer" aria-hidden="true"></i> <span>Dashboard</span></a>
        </li>

        <li><a data-hover="dropdown" href="javascript:void(0)" id="notification"  data-toggle="collapse"  aria-expanded="false"
                class="dropdown-toggle collapsed">
                <i class="fa fa-bell" aria-hidden="true"></i> <span>Notifications</span>
            </a>
            <ul class="list-unstyled collapse" id="notificationSubmenu" >
                <li><a href="javascript:void(0)"  routerLink="notifications"><i class="fa fa-flag" aria-hidden="true"></i> All</a></li>
                <li><a href="javascript:void(0)"  routerLink="new-notifications"><i class="fa fa-plus-square" aria-hidden="true"></i> New</a></li>
            </ul>
        </li>

        <li><a data-hover="dropdown" href="javscript:void(0)" id="configuration"  data-toggle="collapse" aria-expanded="false"
                class="dropdown-toggle collapsed">
                <i class="fa fa-cog" aria-hidden="true"></i> <span>Configuration</span>
            </a>
            <ul class="list-unstyled collapse" id="configurationSubmenu">
                <!-- <li><a href="javascript:void(0)"><i class="fa fa-link" aria-hidden="true"></i> Store Integration</a></li> -->
                <li><a href="javascript:void(0)" routerLink="theme"><i class="fa fa-pencil" aria-hidden="true"></i> Theme</a></li>
                <li><a href="javascript:void(0)" routerLink="banner"><i class="fa fa-picture-o" aria-hidden="true"></i> Banner</a></li>
            </ul>
        </li>
        <li class="active">
            <a target="_blank" routerLink="/documents"><i class="fa fa-question-circle" aria-hidden="true"></i> <span>Help</span></a>
        </li>

    </ul>
</nav>
<!-- sidebar end-->