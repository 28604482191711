<!--Header start here-->
<div class="container-fluid header-bg">
    <div class="container">
        <div class="row">
            <div class="col-3">
             <div class="logo"><img src="assets/images/logo.png" /></div>
            </div>
            <div class="col-9 text-right">
                <div class="menu-links"><a href="/documents"> How It Works</a> &nbsp; | &nbsp; <a href="/developer-instructions">Create developer account</a></div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 help-head">
                <h1>Help Desk |</h1><h2>We are happy to help you</h2>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="pl-3 pr-3">

        <div class="row">
            <div class="col-md-12">
                <h1 class="heading-1">How the App Works?</h1>
            </div>
        </div>
        <!-- breadcrumb and heading end-->
        <!-- pagecontent start-->
        <div class="row ">
            <!-- preview start-->
            <div class="">
                <div id="sidebar-link" class="nav-steps ">
                    <div id="checkdiv"></div>
                </div>
            </div>
            <!-- preview end-->
            <div class="col-md-12">
                <div class="card ">
                    <div class="card-body p-4">
                        <div class="pt-2" id="step1">
                            <!-- <h5 class="mb-4">1. Getting an Apple Developer Account </h5> -->
                            <ol>
                              
                            
                            <li>
                                <span style="color: #0D96D4;"> Sign in</span>
                                <span style="color: #0D96D4;"> <b>:-</b></span>
                                <a href="https://developer.apple.com/programs/enroll/" target="_blank">Review what you
                                    need to enroll</a>
                                Click <b>Start Your Enrollment.</b> You will need an Apple ID to log in and sign up. If
                                you don’t have an Apple ID, you’ll need to
                                <a href="https://appleid.apple.com/account#!&amp;page=create" target="_blank">create
                                    one</a>.
                                </li>
                                <li>
                                <span style="color: #0D96D4;">Set up two-factor authentication :- </span>
                                To keep your account secure, Apple requires <a
                                    href="http://developer.apple.com/support/account/authentication/"
                                    target="_blank">two-factor authentication</a> to sign in. Your organization needs
                                access to a mobile device running iOS 9 or later, or a Mac running OS X El Capitan or
                                later. This should not be someone's personal computer or phone. <a
                                    href="https://support.apple.com/en-us/HT204915" target="_blank">Follow these
                                    instructions to set up two-factor authentication</a>
                            </li>

                            <li>
                                <span style="color: #0D96D4;"> Read and agree to the Developer Agreement:- </span>
                                Check the box to indicate you agree, then click Submit.
                            </li>
                            <li>
                                <span style="color: #0D96D4;"> Complete your account details:- </span>
                                Choose an entity type (for most customers, this is Company / Organization), then click
                                Continue. Confirm you have authority to sign legal agreements on behalf of your
                                organization. Enter your contact information. If necessary, enter the organization
                                information as well. If you don’t know your D-U-N-S number, you can <a
                                    href="https://developer.apple.com/enroll/duns-lookup/"
                                    style="font-family: arial, helvetica, sans-serif; font-size: 16px;"
                                    target="_blank">look it up</a>. Once you’re done, click Continue.
                                </li>
                            <li>
                                <span style="color: #0D96D4;">  Pay the annual $99 fee:- </span>
                                Click Purchase. Enter your billing and credit card information. Click Continue, then
                                Place Order Now. It can take roughly 3-5 days for Apple to approve your license. If it
                                takes any longer than that, <a href="https://developer.apple.com/contact/"
                                    style="font-family: arial, helvetica, sans-serif; font-size: 16px;"
                                    target="_blank">contact Apple</a>.

                                </li>
                        </ol>
                        </div>
                        <div class="mt-4 pt-4" id="step2">
                            <h5 class="mb-4">Getting a Google Developer Account </h5>
                        <ol>
                            <li>
                                <span style="color: #0D96D4;"> Sign :-</span> On the <a
                                    href="https://support.google.com/googleplay/android-developer/answer/6112435?hl=en"
                                    target="_blank">Play Console landing page</a>, click Sign Up for a Developer account
                                under Step 1. You will need a Google ID to log in and sign up. If you don’t have a
                                Google ID, you’ll need to <a href="https://accounts.google.com/" target="_blank">create
                                    one</a>.
                                </li>
                            <li>
                                <span style="color: #0D96D4;"> Read and agree to the Developer Agreement-: </span>Check
                                the box to indicate you agree, then click Continue to Payment
                            </li>
                            <li>
                                <span style="color: #0D96D4;"> Pay the one-time $25 fee-: </span> Enter your billing
                                and credit card information. Click Pay. You will receive a payment complete
                                notification. Click Continue Registration.
                            </li>
                            <li>
                                <span style="color: #0D96D4;"> Complete your account details</span> Enter your contact
                                information. Complete your Developer Profile by entering the organization information as
                                well. Click Complete Registration. You’ll have instant access to your Google Play
                                Console account. For any questions, <a href="https://support.google.com/googleplay/"
                                    style="font-family: arial, helvetica, sans-serif; font-size: 16px;"
                                    target="_blank">contact Google</a>.
                                </li>
                        </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- pagecontent end-->
    </div>
    <!-- footer start-->
    <div id="footer">
        <div class="container-fluid">
            <p class="copyright">&copy; shopimob 2020. All Rights Reserved</p>
        </div>
    </div>
    <!-- footer end-->


</div>