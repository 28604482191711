import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { SidebarComponent } from './sidebar/sidebar.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NewnotificationsComponent } from './new-notifications/newnotifications.component';
import { ThemeComponent } from './theme/theme.component';
import { ProfileComponent } from './profile/profile.component';
import { PlanComponent } from './plan/plan.component';
import { EditNotificationsComponent } from './edit-notifications/edit-notifications.component';
import { MatInputModule } from '@angular/material/input';
import { HttpClientModule } from '@angular/common/http';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxPaginationModule } from 'ngx-pagination';
import { environment } from '../environments/environment';
import {AuthService} from './auth.service';
import { BannerComponent } from './banner/banner.component';
import { DocumentsComponent } from './documents/documents.component';
import { AccountComponent } from './account/account.component';
import { AppSelectionComponent } from './app-selection/app-selection.component';
import { DeveloperInstructionsComponent } from './developer-instructions/developer-instructions.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    SignupComponent,
    DashboardComponent,
    SidebarComponent,
    ForgotPasswordComponent,
    NotificationsComponent,
    NewnotificationsComponent,
    ThemeComponent,
    ProfileComponent,
    PlanComponent,
    EditNotificationsComponent,
    BannerComponent,
    DocumentsComponent,
    AccountComponent,
    AppSelectionComponent,
    DeveloperInstructionsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.config),
    NgMultiSelectDropDownModule,
    AngularFireAuthModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    HttpClientModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    ColorPickerModule,
    ImageCropperModule,
    NgxPaginationModule
  ],
  providers: [ProfileComponent,AuthService,HeaderComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
