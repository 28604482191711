<!-- body container start-->
<div class="container-fluid">
    <div class="pl-3 pr-3">

        <!-- breadcrumb and heading start-->
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent pl-0 pr-0 mb-0 pb-0 pt-4">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">Plan</li>
            </ol>
        </nav>
        <div class="row">
            <div class="col-md-6">
                <h1 class="heading-1">Plan</h1>
            </div>
            <!-- <div class="col-md-6  pt-2 text-right"><a href="javascript:void(0)" class="btn btn-woutline-primary">UPGRADE PLAN</a></div> -->
        </div>
        <!-- breadcrumb and heading end-->


        <!-- pagecontent start-->
        <div class="row ">
            <div class="col-md-8">
                <div class="card ">
                    <div class="card-body ">

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <p class=" mb-1"><span class="badge badge-secondary p-2">Current Plan</span></p>
                                    <label>Business</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <p class="text-muted mb-1">Expired On</p>
                                    <label>22/09/2020</label>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <p class="text-muted mb-1">User Access</p>
                                    <label>02</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <p class="text-muted mb-1">Used Space</p>
                                    <label>20 MB</label>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <p class="text-muted mb-1">Next Billing Date</p>
                                    <label>11/09/2020</label>
                                </div>
                            </div>

                        </div>


                        <div class="clearfix"></div>
                        <!-- <a type="submit" class="btn btn-outline-primary pull-right" href="javascript:void(0)">UPGRADE PLAN</a> -->


                    </div>
                </div>

            </div>
            <!-- current plan start-->
            <div class="col-md-4">


                <div class="bg-primary text-white mt-3 rounded">
                    <div class="p-4">
                        <p class="mb-1">Plan Name</p>
                        <h3 class="heading-3 text-white">Business</h3>
                    </div>
                    <div class="f-sm pb-4 pl-4 pr-4 pt-0">
                        <div class="row ">
                            <div class="col-md-6">
                                <div class="form-group">Access of Users</div>
                            </div>
                            <div class="col-md-6"><label>02</label></div>
                        </div>

                        <div class="row ">
                            <div class="col-md-6">
                                <div class="form-group">Space</div>
                            </div>
                            <div class="col-md-6"><label>50 MB</label></div>
                        </div>

                        <div class="row ">
                            <div class="col-md-6">
                                <div class="form-group">Notification</div>
                            </div>
                            <div class="col-md-6"><label>20 / Month</label></div>
                        </div>

                        <div class="row ">
                            <div class="col-md-6">
                                <div class="form-group">Theme Change</div>
                            </div>
                            <div class="col-md-6"><label>All</label></div>
                        </div>


                    </div>
                </div>
            </div>
            <!-- current plan end-->

        </div>

        <!-- pagecontent end-->


    </div>

    <!-- footer start-->
    <div id="footer">
        <div class="container-fluid">
            <p class="copyright">&copy; shopimob 2020. All Rights Reserved</p>
        </div>
    </div>
    <!-- footer end-->


</div>
<!-- body container end-->