import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NewnotificationsComponent } from './new-notifications/newnotifications.component';
import { EditNotificationsComponent } from './edit-notifications/edit-notifications.component';
import { BannerComponent} from './banner/banner.component';
import { ThemeComponent } from './theme/theme.component';
import { ProfileComponent } from './profile/profile.component';
import { PlanComponent } from './plan/plan.component';
import { DocumentsComponent } from './documents/documents.component';
import { AccountComponent } from './account/account.component';
import { AppSelectionComponent } from './app-selection/app-selection.component';
import { DeveloperInstructionsComponent } from './developer-instructions/developer-instructions.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'signup', component: SignupComponent },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'logout', component: DashboardComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'notifications', component: NotificationsComponent },
  { path: 'new-notifications', component: NewnotificationsComponent },
  { path: 'edit-notifications/:id', component: EditNotificationsComponent },
  { path: 'theme', component: ThemeComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'plan', component: PlanComponent },
  { path: 'banner', component: BannerComponent },
  { path: 'documents', component: DocumentsComponent },
  { path: 'account', component: AccountComponent },
  { path: 'app-type', component: AppSelectionComponent },
  { path: 'developer-instructions', component: DeveloperInstructionsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
