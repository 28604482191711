import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, Subject, combineLatest } from 'rxjs';
import { retry, catchError, tap, first, finalize, map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { AngularFireStorage } from "@angular/fire/storage";
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataservicesService {

  downloadURL:string;
  profilePic:string;

  constructor(public _http: HttpClient, private firestore: AngularFirestore, private storage: AngularFireStorage) { }

  private _refreshImageNeeded$ = new Subject<void>();

  get refreshImageNeeded$() {
    return this._refreshImageNeeded$;
  }

  // POST
  async addNotifications(collectionName, documentId, sub_collection, data) {
    console.log(sub_collection)
    return await this.firestore.collection(collectionName).doc(documentId).collection(sub_collection).add(data);
  }

  // GET
  async getNotificationDetails(collectionName, documentId, sub_collection, uid) {

  let getdetails = await this.firestore.collection(collectionName + '/' + documentId + '/' + sub_collection, ref => ref.where('created_by', '==', uid).limit(5)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
    return getdetails;
}

  // GET
  async getNextNotification(collectionName, documentId, sub_collection, uid,lastRecord) {

    let getdetails = await this.firestore.collection(collectionName + '/' + documentId + '/' + sub_collection, ref => ref.where('created_by', '==', uid).orderBy('created_date', 'desc').startAfter(lastRecord).limit(5)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();

    return getdetails;

  }


  // GET
  async getPreviousNotification(collectionName, documentId, sub_collection, uid,lastRecord,firstInResponse) {

    let getdetails = await this.firestore.collection(collectionName + '/' + documentId + '/' + sub_collection, ref => ref.where('created_by', '==', uid).orderBy('created_date', 'desc').startAt(lastRecord).endBefore(firstInResponse).limit(5)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();

    return getdetails;

  }

  // GET
  async editNotification(collectionName, documentId, sub_collection, id) {
 let getdetails = await this.firestore.collection(collectionName + '/' + documentId + '/' + sub_collection, ref => ref.where(firebase.firestore.FieldPath.documentId(), '==', id)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();

    return getdetails;

  }

  // PUT
  async updateNotification(collectionName, documentId, sub_collection, data, detailsId) {

    let update = await this.firestore.doc(collectionName + '/' + documentId + '/' + sub_collection + '/' + detailsId).update(data);
    return update;

  }

  // delete
  async deleteNotification(collectionName, documentId, sub_collection, deleteId) {

    let deleteNotification = await this.firestore.doc(collectionName + '/' + documentId + '/' + sub_collection + '/' + deleteId).delete();
    return deleteNotification;

  }

  // list
  async userList(collectionName, role) {

    let allUsers = await this.firestore.collection(collectionName, ref => ref.where('role', '==', role)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
    return allUsers;

  }

  // delete
  async userDeviceToken(collectionName, documentId, sub_collection, userIds) {

    let allTokens = await this.firestore.collection(collectionName + '/' + documentId + '/' + sub_collection, ref => ref.where('token.user_id', 'in', userIds)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
    return allTokens;
  }

  // get
  async getUserDetails(collectionName, userId) {

    let getdetails = await this.firestore.collection(collectionName, ref => ref.where(firebase.firestore.FieldPath.documentId(), '==', userId)).valueChanges({ idField: 'id' }).pipe(first()).toPromise();

    return getdetails;
  }

  // update
  async updateUserDetails(collectionName, userId, data) {
    let update = await this.firestore.doc(collectionName + '/' + userId).update(data);
    return update;
  }

  // update
  async uploadImage(file, userid) {
    var n = Date.now();
    // const file = event.target.files[0];
    const filePath = `ShopiMobUsers/${n}`;
    const fileRef = this.storage.ref(filePath);
    let user = JSON.parse(sessionStorage.getItem('userInfo'));
    const uploadTaskSnapshot = await fileRef.put(file);
    const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();

    this.profilePic = downloadURL;
    user['photoURL'] = this.profilePic;
    sessionStorage.setItem('userInfo', JSON.stringify(user));
    let image = { 'profile_pic': this.profilePic }
    this.updateUserDetails('users', userid, image);
    this._refreshImageNeeded$.next();

    return downloadURL;

  }

  async getTemplate(idToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+idToken
      })
    }
    let result = await this._http.get(environment.getTemplateUrl,httpOptions).toPromise();
    return result;
  }


  async publishTemplate(data,idToken:string,shopName:string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+idToken
      })
    }
    let result = await this._http.post(environment.publishTemplateUrl+'?shop='+shopName, data,httpOptions).toPromise();
    return result;
  }

  async checkStoreName(collectionName:string, storeName:string,userId:string) {

    return await this.firestore.collection(collectionName, ref => ref.where(firebase.firestore.FieldPath.documentId(), '==', storeName).where('userId','==',userId)).valueChanges().pipe(first()).toPromise();
  }

  async updateShops(collectionName:string , shopName:string,data){
       return await this.firestore.doc(collectionName + '/' + shopName).update({
      appType: data,
      appStatus:'Inprogress'
    });    
  }
}
