import { Component, OnInit } from '@angular/core';
import { DataservicesService } from '../dataservices.service'
import { finalize } from 'rxjs/operators';
import { AngularFireStorage } from "@angular/fire/storage";
import * as _ from 'lodash';
import { HeaderComponent } from '../header/header.component';

declare var $: any;

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  selectedFile: File = null;
  profilePic: string = '';
  downloadURL;
  logodownloadURL;
  maximumUploadSize: Number = 1000000;
  imageChangedEvent: any = '';
  logoChangedEvent: any = '';
  croppedImage: any = '';
  fileToReturn;
  logo: string;
  banner: string = '';
  themeDetails = { banners: [], body: { 'color': '' }, logo: { Url: '' }, show_banner: true };
  totalBanners = [];
  isLoading: boolean = false;
  show_banner_in_app: boolean = true;
  uploadedBanners;
  shopInfo;
  buttonDisabled: boolean = false;
  displayBanner: boolean;


  constructor(private _shopService: DataservicesService, private storage: AngularFireStorage, private _head: HeaderComponent) { }

  ngOnInit(): void {
    this.shopInfo = JSON.parse(sessionStorage.getItem('shopInfo'));
    this.getParameter();
  }

  ngAfterViewInit() {
    $('.carousel').carousel();
  }


  async getParameter() {
    this.isLoading = true;
    try {
      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      let uidToken = userInfo['stsTokenManager']['accessToken'];
      let details = await this._shopService.getTemplate(uidToken);

      let shopName = this.shopInfo['shopName'];
      let themeName = shopName + '_theme';

      if (details['status'] == 'success') {

        //check if shopname exist or not
        if (details['data'].hasOwnProperty(shopName)) {

          //check if parameter empty or not
          if (details['data'][shopName]['parameters'][themeName]['defaultValue']['value']) {

            this.themeDetails = JSON.parse(details['data'][shopName]['parameters'][themeName]['defaultValue']['value']);

            if (Object.keys(this.themeDetails).length != 0) {
              this.show_banner_in_app = this.themeDetails.show_banner;
              this.displayBanner = this.themeDetails.show_banner;
              if (this.show_banner_in_app == false) {
                $(".slider").css('background-color', "#ccc")
                $('#carouselDiv').css('display', 'None')
                $("#show").removeAttr('checked');
              } else {
                $('#carouselDiv').css('display', 'block')
                $(".slider").css('background-color', "#5423bb")
                $("#show").attr("checked", "checked");
              }
            }
          }
        } else {
          console.log('Not available');
          this.buttonDisabled = true;
        }
      } else {
        if (details['status'] == 'failed' && details['error']['code'] == 'auth/id-token-expired') {
          this._head.signOut();
        }
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  async onSubmit() {
    try {
      this.isLoading = true;

      if ((this.banner && this.themeDetails.banners.length > 0) || this.show_banner_in_app != this.displayBanner) {

        let shopName = this.shopInfo['shopName'];

        this.uploadedBanners = this.themeDetails.banners;

        let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        let uidToken = userInfo['stsTokenManager']['accessToken'];

        this.themeDetails['show_banner'] = this.show_banner_in_app;
        this.themeDetails['banners'] = this.uploadedBanners;

        let result = await this._shopService.publishTemplate(this.themeDetails, uidToken, shopName);
        this.banner = '';

        if (result['status'] == 'failed' && result['error']['code'] == 'auth/id-token-expired') {
          this._head.signOut();
        }
      } else {
        alert('No new banner to upload!');
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  onBannerSelected(event: any) {
    this.isLoading = true;
    const file = event.target.files[0];
    var n = Date.now();
    if (file.type == 'image/jpeg' || file.type == 'image/png') {
      if (file.size < this.maximumUploadSize) {
        this.imageChangedEvent = event;
        const filePath = `ShopiMobNotifications/${n}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(`ShopiMobNotifications/${n}`, file);
        task.snapshotChanges()
          .pipe(
            finalize(() => {
              this.downloadURL = fileRef.getDownloadURL();
              this.downloadURL.subscribe(url => {
                if (url) {
                  this.banner = url;
                  this.themeDetails.banners.push(url);
                  this.isLoading = false;
                }
              });
            })
          )
          .subscribe(url => {
            if (url) {
              console.log(url);
            }
          });
      } else {
        alert('Please select less then 1mb file!');
        this.isLoading = false;
      }
    } else {
      alert('Invalid file format!');
      this.isLoading = false;
    }
  }

  showBanner(event) {
    if (event.target.checked == true) {
      this.show_banner_in_app = true;
      $('#carouselDiv').css('display', 'block')
      $(".slider").css('background-color', "#5423bb")
    } else {
      $(".slider").css('background-color', "#ccc")
      $('#carouselDiv').css('display', 'None')
      this.show_banner_in_app = false;
    }
  }

  deleteBanner(index) {
    this.themeDetails.banners.splice(index, 1);
  }
}
