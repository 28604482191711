import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  isLoading:boolean=false;
  passwordSuccessMsg:boolean=false;
  passwordErrorMsg:boolean=false;
  errorMsg:string;

  constructor(public afAuth: AngularFireAuth) { }

  ngOnInit(): void {
  }

    // Reset Forggot password
    ForgotPassword(passwordResetEmail) {
      this.isLoading=true;
      return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        this.passwordSuccessMsg = true;
        this.passwordErrorMsg=false;
        this.isLoading=false;

      }).catch((error) => {
        this.passwordErrorMsg = true;
        console.log('eror ', error);
        this.errorMsg = error.message;
        this.isLoading=false;

      })
    }

}
