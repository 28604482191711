import { Component, OnInit } from '@angular/core';
import { DataservicesService } from '../dataservices.service';
import { Router } from "@angular/router";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AngularFireStorage } from "@angular/fire/storage";
import { map, finalize, tap } from "rxjs/operators";


@Component({
  selector: 'app-newnotifications',
  templateUrl: './newnotifications.component.html',
  styleUrls: ['./newnotifications.component.css']
})
export class NewnotificationsComponent implements OnInit {
  shopDetails:any;
  details = {};
  isLoading: boolean = false;
  userInfo: any;
  all_users = [];
  sent_to = [];
  dropdownList = [];
  selectedItems = [];
  subject: string;
  description: string;
  schedule_date: string;
  dropdownSettings: IDropdownSettings;
  schedule_time = new Date(new Date().setHours(0, 0, 0, 0));
  weekday: string = 'Sunday';
  selectedFile: File = null;
  profilePic: string = '';
  downloadURL;
  maximumUploadSize: Number = 1000000;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  fileToReturn;
  images: string = '';
  notification_image;
  requiredClass: string = 'validField';
  published: boolean = false;
  interval: string = 'once';
  showdate: boolean = true;
  showweekday: boolean = false;
  shopInfo;

  constructor(private _shopService: DataservicesService, public router: Router, private storage: AngularFireStorage) { }

  async ngOnInit() {
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.shopDetails = JSON.parse(sessionStorage.getItem('shopInfo'));
    
    this.sent_to = [];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'email',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 6,
      allowSearchFilter: true
    }

    this.all_users = await this._shopService.userList('users', 'appUser');
  }


  onItemSelect(item: any) {
    (this.sent_to.length > 0) ? this.requiredClass = 'validField' : this.requiredClass = 'invalidField';
  }


  onItemDeSelect(item: any) {
    (this.sent_to.length > 0) ? this.requiredClass = 'validField' : this.requiredClass = 'invalidField';
  }

  onSelectAll(items: any) {
    (items.length > 0) ? this.requiredClass = 'validField' : this.requiredClass = 'invalidField';
  }

  onDeSelectAll(items: any) {
    (items.length > 0) ? this.requiredClass = 'validField' : this.requiredClass = 'invalidField';
  }


  async addNotification(form) {

    this.isLoading = true;
    if (form.valid) {

      if (this.images != '') {
        this.notification_image = this.images;
      } else {
        this.notification_image = '';
      }

      let inputJson = {
        details: {
          subject: form.value['subject'],
          description: form.value['description'],
          image: this.notification_image
        },
        schedule: {},
      }

      let newSent = [];
      form.value['sent_to'].forEach(function (value) {
        newSent.push(value['id']);
      });

      if (this.interval == 'daily') {
        inputJson['schedule']['time'] = form.value['schedule_time'];
        inputJson['schedule']['interval_type'] = this.interval;
      }

      if (this.interval == 'weekly') {
        inputJson['schedule']['day'] = form.value['weekday'];
        inputJson['schedule']['time'] = form.value['schedule_time'];
        inputJson['schedule']['interval_type'] = this.interval;
      }

      if (this.interval == 'monthly' || this.interval == 'once') {
        inputJson['schedule']['date'] = form.value['schedule_date'];
        inputJson['schedule']['time'] = form.value['schedule_time'];
        inputJson['schedule']['interval_type'] = this.interval;
      }



      inputJson['details']['image'] = this.notification_image;
      inputJson['type'] = 'Custom';
      inputJson['status'] = 'Pending';
      inputJson['published'] = this.published;
      inputJson['sent_to'] = form.value['sent_to'];
      inputJson['created_by'] = this.userInfo['uid'];
      inputJson['created_date'] = new Date().toISOString();

      let allDeviceTokens = await this._shopService.userDeviceToken('ShopiMob', 'deviceTokens', 'shopify', newSent);


      if (allDeviceTokens.length > 0) {
        let tokens = [];
        allDeviceTokens.forEach(function (value) {
          tokens.push(value['token']['token']);
        });

        inputJson['user_device_tokens'] = tokens;
      } else {
        inputJson['user_device_tokens'] = [];
      }

      console.log('inputJson ', inputJson);
      let result = await this._shopService.addNotifications('ShopiMob', 'notifications', this.shopDetails.shop, inputJson);
      this.isLoading = false;
      this.router.navigate(['notifications']);
    } else {
      console.log('Invalid hai');
      this.requiredClass = 'invalidField';
      this.isLoading = false;
    }

  }

  publishNotification(data) {
    this.published = true;
    this.addNotification(data);
  }

  onFileSelected(event: any) {
    this.isLoading = true;
    const file = event.target.files[0];
    var n = Date.now();
    if (file.type == 'image/jpeg' || file.type == 'image/png') {
      if (file.size < this.maximumUploadSize) {
        this.imageChangedEvent = event;
        const filePath = `ShopiMobNotifications/${n}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(`ShopiMobNotifications/${n}`, file);
        task.snapshotChanges()
          .pipe(
            finalize(() => {
              this.downloadURL = fileRef.getDownloadURL();
              this.downloadURL.subscribe(url => {
                if (url) {
                  this.images = url;
                  this.isLoading = false;
                }
              });
            })
          )
          .subscribe(url => {
            if (url) {
              console.log('URL ', url);
            }
          });
      } else {
        alert('Please select less then 1mb file!');
        this.isLoading = false;
      }
    } else {
      alert('Invalid file format!');
      this.isLoading = false;
    }
  }


  selectedInterval(item) {

    this.interval = item.target.value;

    switch (this.interval) {

      case 'daily':
        this.showdate = false;
        this.showweekday = false;
        // this.selectMode = 'single';
        break;
      case 'weekly':
        this.showdate = false;
        this.showweekday = true;
        // this.selectMode = 'range';
        break;
      case 'monthly':
        this.showdate = true;
        this.showweekday = false;
        // this.selectMode = 'range';
        break;
    }
  }
}
