import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
//import { AuthService } from '../auth.service';
import { DataservicesService } from '../dataservices.service';
//import { ProfileComponent } from '../profile/profile.component';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isLoggedIn$;
  userInfo:any;
  userImage:string;
  shopInfo:any;

  constructor(public afAuth: AngularFireAuth, public router: Router,private _shopService: DataservicesService) {
   // this.isLoggedIn$ = this._authService.isLoggedIn;
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.shopInfo = JSON.parse(sessionStorage.getItem('shopInfo'));
    // if (this.userInfo != null) {
    //   this.userImage = this.userInfo['photoURL'];
    // } else {
    //   this.router.navigate(['/']);
    // }
  }

  async ngOnInit() {
    $("#sidebarCollapse").css('display', '');
    $('#sidebarCollapse').toggle(
      function () {
        $('#sidebar').addClass('active')
        $('#notification').removeClass('dropdown-toggle');
        $("#configuration").removeClass('dropdown-toggle');
      }, function () {
        $('#sidebar').removeClass('active');
        $('#notification').addClass('dropdown-toggle');
        $("#configuration").addClass('dropdown-toggle');
      })


    $('#profile_dropdown').mouseenter(function () {
      //dialog open
      $('#profile').addClass('nav-item dropdown topbar-user open');
    });

    $('#profile_dropdown').mouseleave(function () {
      //dialog close
      $('#profile').removeClass('nav-item dropdown topbar-user open');
      $('#profile').addClass('nav-item dropdown topbar-user');
    });

    this._shopService.refreshImageNeeded$.subscribe(async () => {
      await this.updateUserImage();
    })
  }

  async signOut() {
    await this.afAuth.signOut();
    sessionStorage.removeItem('userInfo');
    sessionStorage.removeItem('shopInfo');
    this.router.navigate(['login']);
  }

  async appUsersList() {
    return await this._shopService.userList('users', 'appUser');
  }

  async updateUserImage() {
    let photoUrl = await this._shopService.getUserDetails('users', this.userInfo['uid']);
    this.userImage = photoUrl[0]['profile_pic'];
    if (this.userImage != '') {
      this.afAuth.authState.subscribe(user => {
        user.updateProfile({
          photoURL: this.userImage
        }).then(function () {
        }, function (error) {
          this.isLoading = false;
        });
      })
    }
  }

}
