import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userData: any;
  userInfo: any;
  isLoading: boolean = true;

  constructor(private router: Router, public afAuth: AngularFireAuth, public afs: AngularFirestore) {
    this.isLoading = true;
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.afAuth.authState.subscribe(user => {
      if (user) {
        sessionStorage.setItem('userInfo', JSON.stringify(user));
        this.router.navigate(['dashboard']);
      } else {
        this.isLoading = false;
        sessionStorage.setItem('userInfo', null);
       // this.router.navigate(['login']);
      }
    })
  }

  // Returns true when user is looged in and email is verified && user.emailVerified !== false
  get isLoggedIn(): boolean {
    const user = JSON.parse(sessionStorage.getItem('userInfo'));
    return (user !== null) ? true : false;
  }
}
