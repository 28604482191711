<!-- <app-header *ngIf="router.url != '/login' && router.url != '/signup'"></app-header>
<router-outlet></router-outlet>
<app-footer *ngIf="router.url != '/login' && router.url != '/signup'"></app-footer>  -->

 <!-- <app-header *ngIf="router.url != '/login' && router.url != '/signup' && router.url != '/forgot-password'"></app-header> -->
<router-outlet *ngIf="router.url == '/login' || router.url == '/signup' || router.url == '/forgot-password' || router.url == '/developer-instructions' || router.url == '/documents';else dashboardSection"></router-outlet>

<ng-template #dashboardSection>
    <div class="wrapper">
        <app-sidebar></app-sidebar>
        <div id="content">
            <app-header *ngIf="router.url != '/login' && router.url != '/signup' && router.url != '/forgot-password'"></app-header>
            <router-outlet></router-outlet>
            <app-footer *ngIf="router.url != '/login' && router.url != '/signup' && router.url != '/forgot-password'"></app-footer>
        </div>
    </div>
</ng-template>