<!-- body container start-->
<div class="container-fluid">
  <div class="pl-3 pr-3">

    <!-- breadcrumb and heading start-->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb bg-transparent pl-0 pr-0 mb-0 pb-0 pt-4">
        <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Theme</li>
      </ol>
    </nav>
    <div class="row">
      <div class="col-md-6">
        <h1 class="heading-1">Theme</h1>
      </div>
      <div class="col-md-6  pt-2 text-right"><button href="javascript:void(0)" (click)="resetForm(theme)"
          class="btn btn-woutline-secondary "  [disabled]="buttonDisabled == true">RESET</button> &nbsp; <button (click)="onSubmit()" 
          class="btn btn-woutline-primary">CHANGE THEME</button></div>
    </div>
    <!-- breadcrumb and heading end-->


    <!-- pagecontent start-->
    <div class="row ">
      <div class="col-md-7">
        <form class="p-2" [formGroup]="theme" (ngSubmit)="onSubmit()" ngNativeValidate>
          <div class="card " id="logo">
            <div class="card-body ">

              <div class="form-group mb-4" formGroupName="title">
                <label>Title</label>
                <input type="text" placeholder="Enter Title" name="" class="form-control" formControlName="name"
                  required>

                <div class="form-field mt-2">
                  <div class="row">
                    <div class="col-md-6 col-lg-4 mb-2"><label>Font</label>
                      <select class="form-control" formControlName="font" required>
                        <option value=''>Select Font</option>
                        <option value="Arial">Arial</option>
                        <option value="Times New Roman">Times New Roman</option>
                        <option value="Courier New">Courier New</option>
                        <option value="Verdana">Verdana</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Palatino">Palatino</option>
                        <option value="Garamond">Garamond</option>
                        <option value="Impact">Impact</option>
                        <option value="Comic Sans MS">Comic Sans MS</option>
                        <option value="Helvetica">Helvetica</option>
                      </select> </div>
                    <div class="col-md-6 col-lg-4 mb-2"><label>Font Size</label>
                      <select class="form-control" formControlName="fontSize" required>
                        <option value=''>Select Font Size</option>
                        <option value="1">1px</option>
                        <option value="2">2px</option>
                        <option value="3">3px</option>
                        <option value="4">4px</option>
                        <option value="5">5px</option>
                        <option value="6">6px</option>
                        <option value="7">7px</option>
                        <option value="8">8px</option>
                        <option value="9">9px</option>
                        <option value="10">10px</option>
                        <option value="11">11px</option>
                        <option value="12">12px</option>
                        <option value="13">13px</option>
                        <option value="14">14px</option>
                        <option value="15">15px</option>
                        <option value="16">16px</option>
                        <option value="17">17px</option>
                        <option value="18">18px</option>
                        <option value="19">19px</option>
                        <option value="20">20px</option>
                      </select> </div>
                    <div class="col-md-6 col-lg-4 mb-2">
                      <label>Color</label>
                      <div data-color="#fff" data-color-format="rgba" class="input-group colorpicker-component">
                        <input type="text" class="form-control" [value]="theme.value.title.color"
                          [colorPicker]="theme.value.title.color" formControlName="color"
                          (colorPickerChange)="onColorPickerChange('title',$event)" required />

                        <span class="input-group-btn bg-light"><button type="button"
                            [(colorPicker)]="theme.value.title.color" class="btn"><i
                              [style.color]="theme.value.title.color" class="fa fa-certificate"></i>
                          </button></span>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="form-group mb-4" formGroupName="logo">
                <label class="d-block">Logo</label>
                <div class="upload-file text-center" *ngIf="logo == ''">
                  <span><i class="fa fa-upload" aria-hidden="true"></i> Upload File </span>
                  <input id="inputIncludeFile" type="file" placeholder="Inlcude some file"
                    (change)="onLogoSelected($event)" required>
                </div>
                <div class="form-controls d preview-img d-inline-block p-2 bg-grey " *ngIf="logo != ''">
                  <div class="position-absolute tr-40">
                    <a href="javascript:void(0)" class="heading-2  mr-2" (click)="deleteLogo()"><i
                        class="fa fa-times-circle" aria-hidden="true"></i></a>
                  </div>
                  <img src="{{ logo  }}" class="img-fluid ">

                </div>
              </div>
            </div>
          </div>


          <div class="card " id="body">
            <div class="card-body ">
              <div class="form-group mb-4" formGroupName="heading">
                <label>Heading</label>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-controls mb-2">
                      <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                        aria-orientation="vertical">
                        <a id="v-pills-h1-tab" data-toggle="pill" href="#v-pills-h1" role="tab"
                          aria-controls="v-pills-h1" aria-selected="true" style="font-size:24px;"
                          [ngClass]="theme.value.heading.tag == 'h1' ? 'nav-link active':'nav-link'"
                          (click)="selectedTag('h1')">Hello <i class="fa fa-check-circle pull-right"
                            aria-hidden="true"></i></a>
                        <a id="v-pills-h2-tab" data-toggle="pill" href="#v-pills-h2" role="tab"
                          aria-controls="v-pills-h2" aria-selected="false" style="font-size:22px;"
                          [ngClass]="theme.value.heading.tag == 'h2' ? 'nav-link active':'nav-link'"
                          (click)="selectedTag('h2')">Hello <i class="fa fa-check-circle pull-right"
                            aria-hidden="true"></i></a>
                        <a id="v-pills-h3-tab" data-toggle="pill" href="#v-pills-h3" role="tab"
                          aria-controls="v-pills-h3" aria-selected="false" style="font-size:20px;"
                          [ngClass]="theme.value.heading.tag == 'h3' ? 'nav-link active':'nav-link'"
                          (click)="selectedTag('h3')">Hello <i class="fa fa-check-circle pull-right"
                            aria-hidden="true"></i></a>
                        <a id="v-pills-h4-tab" data-toggle="pill" href="#v-pills-h4" role="tab"
                          aria-controls="v-pills-h4" aria-selected="false" style="font-size:18px;"
                          [ngClass]="theme.value.heading.tag == 'h4' ? 'nav-link active':'nav-link'"
                          (click)="selectedTag('h4')">Hello <i class="fa fa-check-circle pull-right"
                            aria-hidden="true"></i></a>
                        <a id="v-pills-h5-tab" data-toggle="pill" href="#v-pills-h5" role="tab"
                          aria-controls="v-pills-h5" aria-selected="false" style="font-size:16px;"
                          [ngClass]="theme.value.heading.tag == 'h5' ? 'nav-link active':'nav-link'"
                          (click)="selectedTag('h5')">Hello <i class="fa fa-check-circle pull-right"
                            aria-hidden="true"></i></a>
                        <a id="v-pills-h6-tab" data-toggle="pill" href="#v-pills-h6" role="tab"
                          aria-controls="v-pills-h6" aria-selected="false" style="font-size:14px;"
                          [ngClass]="theme.value.heading.tag == 'h6' ? 'nav-link active':'nav-link'"
                          (click)="selectedTag('h6')">Hello <i class="fa fa-check-circle pull-right"
                            aria-hidden="true"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="tab-content" id="v-pills-tabContent">
                      <div class="tab-pane fade show active" id="v-pills-h1" role="tabpanel"
                        aria-labelledby="v-pills-h1-tab">
                        <div class="form-field">
                          <div class="row">
                            <div class="col-md-12"><label>Font</label>
                              <select class="form-control mb-2" formControlName="font" required>
                                <option value=''>Select Font</option>
                                <option value="Arial">Arial</option>
                                <option value="Times New Roman">Times New Roman</option>
                                <option value="Courier New">Courier New</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Palatino">Palatino</option>
                                <option value="Garamond">Garamond</option>
                                <option value="Impact">Impact</option>
                                <option value="Comic Sans MS">Comic Sans MS</option>
                                <option value="Helvetica">Helvetica</option>
                              </select> </div>
                            <div class="col-md-12">
                              <select class="form-control mb-2" formControlName="fontSize" required>
                                <option value=''>Select Font Size</option>
                                <option value="1">1px</option>
                                <option value="2">2px</option>
                                <option value="3">3px</option>
                                <option value="4">4px</option>
                                <option value="5">5px</option>
                                <option value="6">6px</option>
                                <option value="7">7px</option>
                                <option value="8">8px</option>
                                <option value="9">9px</option>
                                <option value="10">10px</option>
                                <option value="11">11px</option>
                                <option value="12">12px</option>
                                <option value="13">13px</option>
                                <option value="14">14px</option>
                                <option value="15">15px</option>
                                <option value="16">16px</option>
                                <option value="17">17px</option>
                                <option value="18">18px</option>
                                <option value="19">19px</option>
                                <option value="20">20px</option>
                              </select> </div>
                            <div class="col-md-12">
                              <label>Color</label>
                              <div data-color="#ff3246" data-color-format="rgba"
                                class="input-group colorpicker-component">
                                <input type="text" class="form-control" [value]="theme.value.heading.color"
                                  [colorPicker]="theme.value.heading.color" formControlName="color"
                                  (colorPickerChange)="onColorPickerChange('heading',$event)" />

                                <span class="input-group-btn bg-light"><button type="button"
                                    [(colorPicker)]="theme.value.heading.color" class="btn"><i
                                      [style.color]="theme.value.heading.color" class="fa fa-certificate"></i>
                                  </button></span></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="form-group mb-4" formGroupName="body">
                <label>Body Text and Paragraph</label>
                <div class="form-field mt-2">
                  <div class="row">
                    <div class="col-md-6 col-lg-4 mb-2"><label>Font</label>
                      <select class="form-control" formControlName="font" required>
                        <option value=''>Select Font</option>
                        <option value="Arial">Arial</option>
                        <option value="Times New Roman">Times New Roman</option>
                        <option value="Courier New">Courier New</option>
                        <option value="Verdana">Verdana</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Palatino">Palatino</option>
                        <option value="Garamond">Garamond</option>
                        <option value="Impact">Impact</option>
                        <option value="Comic Sans MS">Comic Sans MS</option>
                        <option value="Helvetica">Helvetica</option>
                      </select> </div>
                    <div class="col-md-6 col-lg-4 mb-2"><label>Font Size</label>
                      <select class="form-control" formControlName="fontSize" required>
                        <option value=''>Select Font Size</option>
                        <option value="1">1px</option>
                        <option value="2">2px</option>
                        <option value="3">3px</option>
                        <option value="4">4px</option>
                        <option value="5">5px</option>
                        <option value="6">6px</option>
                        <option value="7">7px</option>
                        <option value="8">8px</option>
                        <option value="9">9px</option>
                        <option value="10">10px</option>
                        <option value="11">11px</option>
                        <option value="12">12px</option>
                        <option value="13">13px</option>
                        <option value="14">14px</option>
                        <option value="15">15px</option>
                        <option value="16">16px</option>
                        <option value="17">17px</option>
                        <option value="18">18px</option>
                        <option value="19">19px</option>
                        <option value="20">20px</option>
                      </select> </div>


                    <div class="col-md-6 col-lg-4 mb-2">
                      <label>Color</label>
                      <div data-color="#ff3246" data-color-format="rgba" class="input-group colorpicker-component">
                        <input type="text" [value]="theme.value.body.color" [colorPicker]="theme.value.body.color"
                          (colorPickerChange)="onColorPickerChange('body',$event)" class="form-control"
                          formControlName="color" />
                        <span class="input-group-btn bg-light"><button type="button"
                            [(colorPicker)]="theme.value.body.color" class="btn"><i
                              [style.color]="theme.value.body.color" class="fa fa-certificate"></i>
                          </button></span></div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="card " id="foot">
            <div class="card-body ">
              <div class="form-group mb-4" formGroupName="color">
                <label>Color</label>

                <div class="row">
                  <div class="col-lg-3 col-md-6 mb-2">
                    <p class="mb-0">Primary</p>
                    <div data-color="#ff3246" data-color-format="rgba" class="input-group colorpicker-component">
                      <input type="text" [value]="theme.value.color?.primary" [colorPicker]="theme.value.color?.primary"
                        (colorPickerChange)="onColorPickerChange('color-primary',$event)" class="form-control"
                        formControlName="primary" />
                      <span class="input-group-btn bg-light"><button type="button"
                          [(colorPicker)]="theme.value.color.primary" class="btn"><i
                            [style.color]="theme.value.color.primary" class="fa fa-certificate"></i>
                        </button></span></div>
                  </div>

                  <div class="col-lg-3 col-md-6 mb-2">
                    <p class="mb-0">Secondary</p>
                    <div data-color="#ff3246" data-color-format="rgba" class="input-group colorpicker-component"> <input
                        type="text" [value]="theme.value?.color?.secondary"
                        [colorPicker]="theme.value?.color?.secondary" class="form-control" formControlName="secondary"
                        (colorPickerChange)="onColorPickerChange('color-secondary',$event)" />
                      <span class="input-group-btn bg-light"><button type="button"
                          [(colorPicker)]="theme.value.color.secondary" class="btn"><i
                            [style.color]="theme.value.color.secondary" class="fa fa-certificate"></i>
                        </button></span></div>
                  </div>


                  <div class="col-lg-3 col-md-6 mb-2">
                    <p class="mb-0">Third</p>
                    <div data-color="#ff3246" data-color-format="rgba" class="input-group colorpicker-component"><input
                        type="text" [value]="theme.value?.color?.tertiary" [colorPicker]="theme.value?.color?.tertiary"
                        formControlName="tertiary" (colorPickerChange)="onColorPickerChange('color-third',$event)"
                        class="form-control" /><span class="input-group-btn bg-light"><button type="button"
                          [(colorPicker)]="theme.value.color.tertiary" class="btn"><i
                            [style.color]="theme.value.color.tertiary" class="fa fa-certificate"></i>
                        </button></span></div>
                  </div>

                  <div class="col-lg-3 col-md-6 mb-2">
                    <p class="mb-0">Fourth</p>
                    <div data-color="#ff3246" data-color-format="rgba" class="input-group colorpicker-component"><input
                        type="text" [value]="theme.value?.color?.quaternary"
                        [colorPicker]="theme.value?.color?.quaternary" formControlName="quaternary"
                        (colorPickerChange)="onColorPickerChange('color-fourth',$event)" class="form-control" /><span
                        class="input-group-btn bg-light"><button type="button"
                          [(colorPicker)]="theme.value.color.quaternary" class="btn"><i
                            [style.color]="theme.value.color.quaternary" class="fa fa-certificate"></i>
                        </button></span></div>
                  </div>
                </div>
              </div>
              <div class="form-group mb-4" formGroupName="button">
                <label>Button</label>
                <div class="row mb-2">
                  <div class=" col-lg-6 col-md-12 mb-2" formGroupName="primary">
                    <label>Primary</label>
                    <div class="row">
                      <div class="col-md-6 mb-2">
                        <p class="mb-0">Background </p>
                        <div data-color="#ff3246" data-color-format="rgba" class="input-group colorpicker-component">
                          <input type="text" [value]="theme.value.button?.primary?.backgroundColor"
                            [colorPicker]="theme.value.button?.primary?.backgroundColor"
                            (colorPickerChange)="onColorPickerChange('button-primary-background',$event)"
                            class="form-control" formControlName="backgroundColor" />
                          <span class="input-group-btn bg-light"><button type="button"
                              [(colorPicker)]="theme.value.button.primary.backgroundColor" class="btn"><i
                                [style.color]="theme.value.button?.primary?.backgroundColor"
                                class="fa fa-certificate"></i>
                            </button></span></div>
                      </div>
                      <div class="col-md-6 mb-2">
                        <p class="mb-0">Text Color</p>
                        <div data-color="#ff3246" data-color-format="rgba" class="input-group colorpicker-component">
                          <input type="text" [value]="theme.value.button?.primary?.textColor"
                            [colorPicker]="theme.value.button?.primary?.textColor"
                            (colorPickerChange)="onColorPickerChange('button-primary-text',$event)" class="form-control"
                            formControlName="textColor" />
                          <span class="input-group-btn bg-light"><button type="button"
                              [(colorPicker)]="theme.value.button.primary.textColor" class="btn"><i
                                [style.color]="theme.value.button?.primary?.textColor" class="fa fa-certificate"></i>
                            </button></span></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-12 mb-2" formGroupName="secondary">
                    <label>Secondary</label>
                    <div class="row">
                      <div class="col-md-6 mb-2">
                        <p class="mb-0">Background </p>
                        <div data-color="#ff3246" data-color-format="rgba" class="input-group colorpicker-component">
                          <input type="text" [value]="theme.value.button?.secondary?.backgroundColor"
                            formControlName="backgroundColor"
                            (colorPickerChange)="onColorPickerChange('button-secondary-background',$event)"
                            [colorPicker]="theme.value.button?.secondary?.backgroundColor" class="form-control" /><span
                            class="input-group-btn bg-light"><button type="button"
                              [(colorPicker)]="theme.value.button.secondary.backgroundColor" class="btn"><i
                                [style.color]="theme.value.button?.secondary?.backgroundColor"
                                class="fa fa-certificate"></i>
                            </button></span></div>
                      </div>
                      <div class="col-md-6 mb-2">
                        <p class="mb-0">Text Color</p>
                        <div data-color="#ff3246" data-color-format="rgba" class="input-group colorpicker-component">
                          <input type="text" [value]="theme.value.button?.secondary?.textColor"
                            formControlName="textColor"
                            (colorPickerChange)="onColorPickerChange('button-secondary-text',$event)"
                            [colorPicker]="theme.value.button?.secondary?.textColor" class="form-control" /><span
                            class="input-group-btn bg-light"><button type="button"
                              [(colorPicker)]="theme.value.button.secondary.textColor" class="btn"><i
                                [style.color]="theme.value.button?.secondary?.textColor" class="fa fa-certificate"></i>
                            </button></span></div>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="row mb-2">
                  <div class="col-lg-6 col-md-12 mb-2" formGroupName="success">
                    <label>Success</label>
                    <div class="row">
                      <div class="col-md-6 mb-2">
                        <p class="mb-0">Background </p>
                        <div data-color="#ff3246" data-color-format="rgba" class="input-group colorpicker-component">
                          <input type="text" [value]="theme.value.button?.success?.backgroundColor"
                            [colorPicker]="theme.value.button?.success?.backgroundColor"
                            formControlName="backgroundColor"
                            (colorPickerChange)="onColorPickerChange('success-background',$event)"
                            class="form-control" /><span class="input-group-btn bg-light"><button type="button"
                              [(colorPicker)]="theme.value.button.success.backgroundColor" class="btn"><i
                                [style.color]="theme.value.button?.success?.backgroundColor"
                                class="fa fa-certificate"></i>
                            </button></span></div>
                      </div>
                      <div class="col-md-6 mb-2">
                        <p class="mb-0">Text Color</p>
                        <div data-color="#ff3246" data-color-format="rgba" class="input-group colorpicker-component">
                          <input type="text" [value]="theme.value.button?.success?.textColor"
                            [colorPicker]="theme.value.button?.success?.textColor" formControlName="textColor"
                            (colorPickerChange)="onColorPickerChange('success-text',$event)"
                            class="form-control" /><span class="input-group-btn bg-light"><button type="button"
                              [(colorPicker)]="theme.value.button.success.textColor" class="btn"><i
                                [style.color]="theme.value.button?.success?.textColor" class="fa fa-certificate"></i>
                            </button></span></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-12 mb-2" formGroupName="alert">
                    <label>Alert</label>
                    <div class="row">
                      <div class="col-md-6 mb-2">
                        <p class="mb-0">Background </p>
                        <div data-color="#ff3246" data-color-format="rgba" class="input-group colorpicker-component">
                          <input type="text" [value]="theme.value.button?.alert?.backgroundColor"
                            [colorPicker]="theme.value.button?.alert?.backgroundColor" formControlName="backgroundColor"
                            (colorPickerChange)="onColorPickerChange('alert-background',$event)"
                            class="form-control" /><span class="input-group-btn bg-light"><button type="button"
                              [(colorPicker)]="theme.value.button.alert.backgroundColor" class="btn"><i
                                [style.color]="theme.value.button?.alert?.backgroundColor"
                                class="fa fa-certificate"></i>
                            </button></span></div>
                      </div>
                      <div class="col-md-6 mb-2">
                        <p class="mb-0">Text Color</p>
                        <div data-color="#ff3246" data-color-format="rgba" class="input-group colorpicker-component">
                          <input type="text" [value]="theme.value.button?.alert?.textColor"
                            [colorPicker]="theme.value.button?.alert?.textColor" formControlName="textColor"
                            (colorPickerChange)="onColorPickerChange('alert-text',$event)" class="form-control" /><span
                            class="input-group-btn bg-light"><button type="button"
                              [(colorPicker)]="theme.value.button.alert.textColor" class="btn"><i
                                [style.color]="theme.value.button?.alert?.textColor" class="fa fa-certificate"></i>
                            </button></span></div>
                      </div>
                    </div>
                  </div>


                </div>


              </div>


              <div class="form-group mb-4" formGroupName="background">
                <div class="row">
                  <div class="col-lg-3 col-md-6"> <label>Backgound</label>
                    <div data-color="#ff3246" data-color-format="rgba" class="input-group colorpicker-component"> <input
                        type="text" [value]="theme.value.background?.color"
                        [colorPicker]="theme.value.background?.color" class="form-control" formControlName="color"
                        (colorPickerChange)="onColorPickerChange('background',$event)" /><span
                        class="input-group-btn bg-light"><button type="button"
                          [(colorPicker)]="theme.value.background.color" class="btn"><i
                            [style.color]="theme.value.background?.color" class="fa fa-certificate"></i>
                        </button></span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="clearfix"></div>
          <button type="submit" class="btn btn-outline-primary pull-right"  href="">Save</button>
            <button  (click)="resetForm(theme)" class="btn btn-woutline-secondary pull-right mr-4"  [disabled]="buttonDisabled == true" href="">RESET</button>
        </form>
      </div>
      <!-- preview start-->
      <div class="col-md-5">
        <div id="sidebar-preview">
          <div id="carouselExampleCaptions" class="carousel slide preview" data-ride="carousel" data-interval="false">
            <ol class="carousel-indicators">
              <li data-target="#logo" data-slide-to="0" class="active"></li>
              <!-- <li data-target="#carouselExampleCaptions" data-slide-to="1"></li> -->
              <li data-target="#body" data-slide-to="2"></li>
              <li data-target="#foot" data-slide-to="3"></li>
            </ol>
            <div class="carousel-inner ">
              <div class="{{ firstImage }}">
                <div class="preview-container" style="background-color:{{theme.value.body.color}}">
                  <div class="logo-preview"> <img src="{{logo}}"> </div>
                </div>
              </div>
              <!-- <div class="carousel-item  p-0">
<div class="preview-container" style="background-color:{{theme.value.body.color}}">
<div class="row">
<div class="col-md-12 text-left pt-2" style="color:#fff;"> <i class="fa fa-bars ml-3 mt-2" aria-hidden="true"></i></div>
  <div class="col-md-12 mt-2" style="background-color:#fff; " >
<div class="text-center pt-2" > <img src="assets/images/preview/img-banner.png" ></div>

  <div class="pl-3 pr-3 pt-3 pb-3" style="background-color:#fff;" >
  <div class="row"><div class="col-8"><span  style="color:{{theme.value.heading.color}}; font-size:{{theme.value.heading.fontSize}}px;font-family:{{theme.value.heading.font}}">Exclusive Offer</span> </div>
   <div class="col-4 text-right"><span style="color:{{theme.value.body.color}}; font-size:{{theme.value.body.fontSize}}px;font-family:{{theme.value.body.font}}">See all</span></div></div>
   
   <div class="row mt-2">
   <div class="col-6 pr-1"><div class="border rounded p-2" ><img src="assets/images/preview/img-banana.png" >		
   <span style="color:{{theme.value.heading.color}}; font-size:{{theme.value.heading.fontSize}}px;font-family:{{theme.value.heading.font}}">Organic Banana</span> <span  style="color:{{theme.value.body.color}}; font-size:{{theme.value.body.fontSize}}px;font-family:{{theme.value.body.font}}">7pcs, Pricing</span> 
   <div class="row mt-2">
    <div class="col-12"> <span style="color:{{theme.value.body.color}}; font-size:{{theme.value.body.fontSize}}px;font-family:{{theme.value.body.font}}">$4.00</span></div>
   <div class="col-12"><button type="button" class="btn" style="background-color:{{theme.value.button?.primary?.backgroundColor}}; color:{{theme.value.button?.primary?.textColor}};">+</button></div>
   </div>
   
   </div></div>
   
   <div class="col-6 pl-1"><div class="border rounded p-2" ><img src="assets/images/preview/img-apple.png" >		
   <span  style="color:{{theme.value.heading.color}}; font-size:{{theme.value.heading.fontSize}}px;font-family:{{theme.value.heading.font}}">Red Apple</span> <span style="color:{{theme.value.body.color}}; font-size:{{theme.value.body.fontSize}}px;font-family:{{theme.value.body.font}}">1Kg, Pricing</span> 
   <div class="row mt-4">
    <div class="col-12"> <span  style="color:{{theme.value.body.color}}; font-size:{{theme.value.body.fontSize}}px;font-family:{{theme.value.body.font}}">$4.00</span></div>
   <div class="col-12"><button type="button" class="btn" style="background-color:{{theme.value.button?.primary?.backgroundColor}}; color:{{theme.value.button?.primary?.textColor}};">+</button></div>
   </div>
   </div></div>
   
   </div>
   
   <div class="row mt-2"><div class="col-8"><span style="color:{{theme.value.heading.color}}; font-size:{{theme.value.heading.fontSize}}px;font-family:{{theme.value.heading.font}}">Best Selling</span> </div>
   <div class="col-4 text-right"><span style="color:{{theme.value.body.color}}; font-size:{{theme.value.body.fontSize}}px;font-family:{{theme.value.body.font}}">See all</span></div></div>
   
   
   
   </div>
 
  </div>
</div>
</div>
</div> -->


              <div class="{{ secondImage }}">
                <div class="preview-container" style="background-color:{{theme.value.body.color}}">
                  <div class="row">
                    <div class="col-md-12 text-left pt-2" style="color:#fff;"> <i class="fa fa-angle-left ml-3 mt-2"
                        aria-hidden="true"></i></div>
                    <div class="col-md-12 ">
                      <div class="text-center pt-3" style="margin-bottom:-10px;"> <img
                          src="assets/images/preview/img-fruit.png"></div>

                      <div class="pl-3 pr-3 pt-3 pb-3" style="background-color:#fff;">
                        <p><span class="d-block"
                            style="color:{{theme.value.heading.color}}; font-size:{{theme.value.heading.fontSize}}px;font-family:{{theme.value.heading.font}}">Natural
                            Red Apple</span><span
                            style="color:{{theme.value.body.color}}; font-size:{{theme.value.body.fontSize}}px;font-family:{{theme.value.body.font}}">1kg,
                            Price</span></p>
                        <div class="row">
                          <div class="col-6">
                            <div class="product-Quantity  mr-2"><span class="pull-left pr-2  f-sm qnt"
                                style="color:{{theme.value.body.color}}; font-size:{{theme.value.body.fontSize}}px;font-family:{{theme.value.body.font}}">Qty:</span>
                              <div class="qnt-minus pull-left f-sm "><span>-</span></div>
                              <div class="qnt-num pull-left  f-sm ">1</div>
                              <div class="qnt-plus pull-left f-sm"><span>+</span></div>
                            </div>
                          </div>
                          <div class="col-6"
                            style="color:{{theme.value.body.color}}; font-size:{{theme.value.body.fontSize}}px;font-family:{{theme.value.body.font}}">
                            $ 4.99</div>
                        </div>
                        <div class="accordion" id="accordionPreview">
                          <div class="card m-0 p-0 border-0">
                            <div class="card-header m-0 p-0 border-0 bg-transparent" id="headingOne">
                              <a class="btn btn-link btn-block text-left p-0" type="button" data-toggle="collapse"
                                data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                                style="color:{{theme.value.heading.color}}; font-size:{{theme.value.heading.fontSize}}px;font-family:{{theme.value.heading.font}}">
                                Product Details
                              </a>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                              data-parent="#accordionPreview">
                              <div class="card-body p-0 f-sm"
                                style="color:{{theme.value.body.color}}; font-size:{{theme.value.body.fontSize}}px;font-family:{{theme.value.body.font}}">
                                Lorem ipsum lorem Ipsum lorem ipsum lorem Ipsum
                              </div>
                            </div>
                          </div>
                          <div class="card m-0 p-0 border-0">
                            <div class="card-header m-0 p-0 border-0 bg-transparent" id="headingTwo">
                              <a class="btn btn-link btn-block text-left collapsed p-1" type="button"
                                data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                                aria-controls="collapseTwo"
                                style="color:{{theme.value.heading.color}}; font-size:{{theme.value.heading.fontSize}}px;font-family:{{theme.value.heading.font}}">
                                Nutritions
                              </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                              data-parent="#accordionPreview">
                              <div class="card-body p-0 f-sm"
                                style="color:{{theme.value.body.color}}; font-size:{{theme.value.body.fontSize}}px;font-family:{{theme.value.body.font}}">
                                Lorem ipsum lorem Ipsum lorem ipsum lorem Ipsum
                              </div>
                            </div>
                          </div>
                          <div class="card m-0 p-0 border-0">
                            <div class="card-header m-0 p-0 border-0 bg-transparent" id="headingThree">
                              <a class="btn btn-link btn-block text-left collapsed p-1" type="button"
                                data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                                aria-controls="collapseThree"
                                style="color:{{theme.value.heading.color}}; font-size:{{theme.value.heading.fontSize}}px;font-family:{{theme.value.heading.font}}">
                                Review
                              </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                              data-parent="#accordionPreview">
                              <div class="card-body p-0 f-sm"
                                style="color:{{theme.value.body.color}}; font-size:{{theme.value.body.fontSize}}px;font-family:{{theme.value.body.font}}">
                                Lorem ipsum lorem Ipsum lorem ipsum lorem Ipsum
                              </div>
                            </div>
                          </div>
                        </div>
                        <button type="button" class="btn w-100"
                          style="background-color:{{theme.value.button?.primary?.backgroundColor}};color:{{theme.value.button?.primary?.textColor}}">Add
                          to Basket</button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="{{ thirdImage }}">
                <div class="preview-container" style="background-color:{{theme.value.body.color}};">
                  <div class="preview-overlay">
                    <div class="row">
                      <div class="col-md-12">
                        <div
                          style="background-color:{{theme.value.button?.success?.backgroundColor}}; color:{{theme.value.button?.success?.textColor}}; position:relative;"
                          class="p-3 rounded ml-3 mr-3 mt-5 mb-2">
                          <div class="position-absolute tr-10"><span style="color:#fff;"><i class="fa fa-times"
                                aria-hidden="true"></i></span></div>
                          <div
                            style="font-size:{{theme.value.heading.fontSize}}px;font-family:{{theme.value.heading.font}};color:{{theme.value.heading.color}}">
                            Home</div>
                          <div style="font-size:{{theme.value.body.fontSize}}px;font-family:{{theme.value.body.font}}">
                            You successfully read this
                            important alert message.</div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div
                          style="background-color:{{theme.value.button?.alert?.backgroundColor}}; color:{{theme.value.button?.alert?.textColor}}; position:relative;"
                          class="p-3 rounded ml-3 mr-3 mt-2 mb-2">
                          <div class="position-absolute tr-10"><span style="color:#fff;"><i class="fa fa-times"
                                aria-hidden="true"></i></span></div>
                          <div
                            style="font-size:{{theme.value.heading.fontSize}}px;font-family:{{theme.value.heading.font}};color:{{theme.value.heading.color}}">
                            Oh snap!</div>
                          <div style="font-size:{{theme.value.body.fontSize}}px;font-family:{{theme.value.body.font}}">
                            Change a few things up and
                            try submitting again.</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-left pt-2" style="color:#fff;"> <i class="fa fa-angle-left ml-2"
                        aria-hidden="true"></i></div>
                    <div class="col-md-12 ">
                      <div class="text-center pt-3" style="margin-bottom:-10px;"> <img
                          src="images/preview/img-fruit.png"></div>

                      <div class="pl-3 pr-3 pt-3 pb-3" style="background-color:#fff;">
                        <p><span class="d-block" style="color:#333; font-size:12px;">Natural Red Apple</span><span
                            style="font-size:11px; color:#666;">1kg, Price</span></p>
                        <div class="row">
                          <div class="col-6">
                            <div class="product-Quantity  mr-2"><span class="pull-left pr-2  f-sm qnt">Qty:</span>
                              <div class="qnt-minus pull-left f-sm "><span>-</span></div>
                              <div class="qnt-num pull-left  f-sm ">1</div>
                              <div class="qnt-plus pull-left f-sm"><span>+</span></div>
                            </div>
                          </div>
                          <div class="col-6">$ 4.99</div>
                        </div>
                        <div class="accordion" id="accordionPreview">
                          <div class="card m-0 p-0 border-0">
                            <div class="card-header m-0 p-0 border-0 bg-transparent" id="headingOne">
                              <a class="btn btn-link btn-block text-left p-0" type="button" data-toggle="collapse"
                                data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                                style="color:#333; font-size:12px;">
                                Product Details
                              </a>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                              data-parent="#accordionPreview">
                              <div class="card-body p-0 f-sm">
                                Lorem ipsum lorem Ipsum lorem ipsum lorem Ipsum
                              </div>
                            </div>
                          </div>
                          <div class="card m-0 p-0 border-0">
                            <div class="card-header m-0 p-0 border-0 bg-transparent" id="headingTwo">
                              <a class="btn btn-link btn-block text-left collapsed p-1" type="button"
                                data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                                aria-controls="collapseTwo" style="color:#333; font-size:12px;">
                                Nutritions
                              </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                              data-parent="#accordionPreview">
                              <div class="card-body p-0 f-sm">
                                Lorem ipsum lorem Ipsum lorem ipsum lorem Ipsum
                              </div>
                            </div>
                          </div>
                          <div class="card m-0 p-0 border-0">
                            <div class="card-header m-0 p-0 border-0 bg-transparent" id="headingThree">
                              <a class="btn btn-link btn-block text-left collapsed p-1" type="button"
                                data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                                aria-controls="collapseThree" style="color:#333; font-size:12px;">
                                Review
                              </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                              data-parent="#accordionPreview">
                              <div class="card-body p-0 f-sm">
                                Lorem ipsum lorem Ipsum lorem ipsum lorem Ipsum
                              </div>
                            </div>
                          </div>
                        </div>
                        <button type="button" class="btn w-100" style="background-color:#029246; color:#fff;">Add to
                          Basket</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- preview end-->
    </div>
    <!-- pagecontent end-->
  </div>

  <!-- footer start-->
  <div id="footer">
    <div class="container-fluid">
      <p class="copyright">&copy; shopimob 2020. All Rights Reserved</p>
    </div>
  </div>
  <!-- footer end-->


</div>
<!-- body container end-->
<div class="loading" *ngIf="isLoading == true">Loading</div>