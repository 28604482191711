import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {


  constructor(private _router:Router) { }

  ngOnInit(): void {

    $('#notification').click(function () {
      $('#notificationSubmenu').slideToggle('fast');
      $(this).toggleClass("collapsed");
    });
    $('#configuration').click(function () {
      $('#configurationSubmenu').slideToggle('fast');
      $(this).toggleClass("collapsed");
    });
  }

  redirectTo(uri:string){
    this._router.routeReuseStrategy.shouldReuseRoute = function(){return false;};
    this._router.navigateByUrl('banner', {skipLocationChange: true}).then(()=>
    this._router.navigate([uri]));
 }


}
