import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { User } from '../models/user.models';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  user: User;
  userData = {};
  passwordNotMatch: boolean = false;
  isLoading: boolean = false;
  matchingPassword: string = 'Password not match';
  submitButtonDisabled: boolean = false;
  conditonsMsg: string;
  pattern = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
  password;
  confirm_password;
  successMsg: boolean = false;
  errorMsg: string = '';
  showPassword: boolean;
  showConfirmPassword: boolean;

  constructor(public afAuth: AngularFireAuth, public afs: AngularFirestore, public router: Router) { }

  ngOnInit(): void {
    this.showPassword = false;
    this.showConfirmPassword = false;
  }

  async register(form) {
    try {
      this.isLoading = true;
      if (form.valid) {
        let email = form.value['user_email'];
        let password = form.value['password'];
        let username = form.value['user_name'];
        const result = await this.afAuth.createUserWithEmailAndPassword(email, password);
        let response = result.user;
        let updateProfile = await response.updateProfile({ displayName: username });
              
        this.userData['uid'] = response['uid'];
        this.userData['email'] = response['email'];
        this.userData['username'] = username;
        this.userData['emailVerified'] = response['emailVerified'];
        this.userData['role'] = 'Admin';

       // console.log('this.userData ', this.userData);
        let setResult = await this.setUserData(this.userData);
      //  console.log('setResult ',setResult);

        if (setResult == 'success') {
          await this.sendEmailVerification();
          this.successMsg = true;
          this.errorMsg = '';
          form.reset();
          this.isLoading = false;
        }else{
          this.isLoading=false;
          form.reset();
          this.successMsg=false;
          this.errorMsg='Please Try Again!';
        }
      }
    } catch (error) {
      console.log('error ', error);
      this.errorMsg = error.message;
      this.isLoading = false;
    }
  }

  // Send email verfificaiton when new user sign up
  async sendEmailVerification() {
    return (await this.afAuth.currentUser).sendEmailVerification();
  }

  comparePassword(password, confirmPassword) {
    if (confirmPassword != '') {
      if (password != confirmPassword) {
        this.passwordNotMatch = true;
        this.submitButtonDisabled = true;
      } else {
        this.passwordNotMatch = false;
        this.submitButtonDisabled = false;
      }
    }
  }


  async setUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      email: user.email,
      user_name: user.username,
      status: 'Active',
      isInvited: false,
      emailVerified: user.emailVerified,
      role: user.role,
      created_at: new Date().toISOString()
    }
    userRef.set(userData, {
      merge: true
    })

    return 'success';
  }

  // click event function toggle
  tooglePassword() {
    this.showPassword = !this.showPassword;
  }

  toogleConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}
