<div id="bg"></div>

<!--Header start here-->
<div class="container-small">
    <div class="d-flex  flex-md-row align-items-center  px-md-4 ">
        <h1 class="my-0 mr-md-auto font-weight-normal"><img src="assets/images/logo.png" /></h1>
        <!-- <a class="btn btn-menu " routerLink="/login">Login</a>
        <a class="btn btn-menu active" routerLink="/signup">Signup</a> -->
    </div>
</div>
<!--Header end here-->
<div class="container-small">
    <div class="page-form ">
        <div class="row">
            <div class="col-lg-5 col-md-6 text-center">
                <div class="img-intro text-center"><img src="assets/images/img-intro.png" class="img-fluid  " /></div>
            </div>
            <div class="col-lg-7 col-md-6">
                <form class="form    ">
                    <div class="header-content">
                        <h2>Reset Password</h2>
                    </div>
                    <div class="body-content">

                        <div class="form-group mb-3">
                            <div class="input-icon left"><i class="fa fa-envelope"></i><input type="text"
                                    placeholder="Email" name="useremail" #useremail class="form-control">
                            </div>
                        </div>



                        <!-- <div class="form-group mb-3">
                            <div class="input-icon left"><i class="fa fa-lock"></i> <i
                                    class="fa fa-eye right"></i><input type="password" placeholder="Password"
                                    name="password" class="form-control">
                            </div>
                        </div>

                        <div class="form-group mb-3">
                            <div class="input-icon left"><i class="fa fa-lock"></i> <i
                                    class="fa fa-eye right"></i><input type="password" placeholder="Confirm Password"
                                    name="password" class="form-control">
                            </div>
                        </div> -->


                        <div class="clearfix"></div>
                        <button type="button" (click)="ForgotPassword(useremail.value)" class="btn btn-smob">Reset Password</button>


                        <div class="alert alert-danger mt-4 mb-4" role="alert" *ngIf="passwordErrorMsg == true">
                          {{errorMsg}}</div>

                        <div class="alert alert-success mt-4 mb-4" role="alert" *ngIf="passwordSuccessMsg == true">
                            A confirmation message send on registered email address, If you do'nt receive <a
                                href="javascript:void(0)" (click)="ForgotPassword(useremail.value)">click here.</a></div>


                        <!-- <div class="alert alert-success mt-4 mb-4" role="alert">
                            You have successfully changed your password. <br>
                            Please login with registered email address <a href="index.html">click here.</a></div> -->




                        <p class="text-center pt-5 mb-0">Back to Login  <a class="btn-register"
                                routerLink="/login">Login</a></p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!--Body end here-->

<!--Footer start here-->
<footer class="footer  pt-5 pb-2  text-center">
    <div class="container-small">
        <p class="text-white"> &copy; shopimob 2020, All Rights Reserved</p>
    </div>
</footer>
<!--Footer end here-->


<div class="loading" *ngIf="isLoading == true">Loading</div>