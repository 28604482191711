import { HostListener, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { DataservicesService } from '../dataservices.service'
import { finalize } from 'rxjs/operators';
import { AngularFireStorage } from "@angular/fire/storage";
import * as _ from 'lodash';
import { HeaderComponent } from '../header/header.component';

declare var $: any;

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.css']
})
export class ThemeComponent implements OnInit {

  color: string;
  theme: FormGroup;
  currentTag: string = '';
  downloadURL;
  logodownloadURL;
  maximumUploadSize: Number = 1000000;
  logoChangedEvent: any = '';
  logo: string = 'assets/images/logo1.png';
  themeJson;
  isLoading: boolean = false;
  firstImage: string = "carousel-item p-0 active";
  secondImage: string = "carousel-item p-0";
  thirdImage: string = "carousel-item p-0";
  shopInfo;
  buttonDisabled: boolean = false;

  constructor(private _shopService: DataservicesService, private storage: AngularFireStorage, private _head: HeaderComponent) { }

  @HostListener('window:scroll', [])
  onWindowScroll() {

    if (document.documentElement.scrollTop >= 0 && document.documentElement.scrollTop < 500) {

      this.firstImage = 'carousel-item p-0 active'
      this.secondImage = 'carousel-item p-0'
      this.thirdImage = 'carousel-item p-0'
    }
    if (document.documentElement.scrollTop > 501 && document.documentElement.scrollTop < 900) {

      this.firstImage = 'carousel-item p-0'
      this.secondImage = 'carousel-item p-0 active'
      this.thirdImage = 'carousel-item p-0'

    }
    if (document.documentElement.scrollTop > 901) {
      this.firstImage = 'carousel-item p-0'
      this.secondImage = 'carousel-item p-0'
      this.thirdImage = 'carousel-item p-0 active'

    }
  }

  ngOnInit() {

    this.shopInfo = JSON.parse(sessionStorage.getItem('shopInfo'));
    $(function () {
      var offset = $("#sidebar-preview").offset();
      var topPadding = 15;
      $(window).scroll(function () {
        if ($(window).scrollTop() > offset.top) {
          $("#sidebar-preview").stop().animate({
            marginTop: $(window).scrollTop() - offset.top + topPadding
          });
        } else {
          $("#sidebar-preview").stop().animate({
            marginTop: 0
          });
        };
      });
    });
  
    this.setForm();
    this.getParameter();
  }

  setForm() {
    this.theme = new FormGroup({
      "title": new FormGroup({
        "color": new FormControl('#ffffff'),
        "font": new FormControl('Arial'),
        "fontSize": new FormControl('14'),
        "name": new FormControl('Design your theme')
      }),
      "logo": new FormGroup({
        "Url": new FormControl('')
      }),
      "heading": new FormGroup({
        "color": new FormControl('#ffffff'),
        "font": new FormControl('Arial'),
        "fontSize": new FormControl('14'),
        "tag": new FormControl('h3')
      }),
      'color': new FormGroup({
        "primary": new FormControl('#816a6a'),
        "secondary": new FormControl('#977c7c'),
        "tertiary": new FormControl('#977c7c'),
        "quaternary": new FormControl('#292727')
      }),
      'body': new FormGroup({
        'color': new FormControl('rgba(13,13,13,0.03)'),
        'font': new FormControl('Arial'),
        'fontSize': new FormControl('14'),
      }),
      'button': new FormGroup({
        'primary': new FormGroup({
          "backgroundColor": new FormControl('#292727'),
          "textColor": new FormControl('#ffffff')
        }),
        'secondary': new FormGroup({
          "backgroundColor": new FormControl('#292727'),
          "textColor": new FormControl('#ffffff')
        }),
        'success': new FormGroup({
          "backgroundColor": new FormControl('#292727'),
          "textColor": new FormControl('#ffffff')
        }),
        'alert': new FormGroup({
          "backgroundColor": new FormControl('#292727'),
          "textColor": new FormControl('#ffffff')
        }),
      }),
      'background': new FormGroup({
        'color': new FormControl('#ffffff'),
      }),
    });
  }


  async getParameter() {
    this.isLoading = true;
    try {
      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));

      let shopName = this.shopInfo['shopName'];
      let themeName = shopName + '_theme';
      let themeDetails;

      let uidToken = userInfo['stsTokenManager']['accessToken'];
      let details = await this._shopService.getTemplate(uidToken);

      //check if status is success
      if (details['status'] == 'success') {

        //check if shopname exist or not
        if (details['data'].hasOwnProperty(shopName)) {

          //check if parameter empty or not
          if (details['data'][shopName]['parameters'][themeName]['defaultValue']['value']) {

            themeDetails = JSON.parse(details['data'][shopName]['parameters'][themeName]['defaultValue']['value']);

            this.themeJson = JSON.parse(details['data'][shopName]['parameters'][themeName]['defaultValue']['value']);

            if (Object.keys(themeDetails).length != 0) {
              this.logo = (themeDetails['logo'] ? themeDetails['logo']['Url'] : '');
              this.currentTag = (themeDetails['heading'] ? themeDetails['heading']['tag'] : '');
              delete themeDetails['banners'];
              delete themeDetails['show_banner'];
              console.log('themeDetails ', typeof themeDetails);
              this.theme.setValue(themeDetails);
            }
          }
        } else {
          console.log('Not available');
          this.buttonDisabled = true;
        }
      } else {
        if (details['status'] == 'failed' && details['error']['code'] == 'auth/id-token-expired') {
          this._head.signOut();
        }
      }
      this.isLoading = false;

    } catch (error) {
      console.log('eer ', error);
      this.isLoading = false;
    }
  }

  async onSubmit() {
    try {
      this.isLoading = true;
      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      let uidToken = userInfo['stsTokenManager']['accessToken'];

      let finalJson = this.theme.value;
      finalJson['logo']['Url'] = this.logo;
      finalJson['heading']['tag'] = this.currentTag;

      let finalTheme = await this.converInNumber(finalJson);
      if (this.themeJson != undefined) {
        if (this.themeJson.hasOwnProperty('show_banner')) {
          finalTheme['show_banner'] = this.themeJson.show_banner;
        } else {
          finalTheme['show_banner'] = false;
        }

        if (this.themeJson.hasOwnProperty('banners')) {
          finalTheme['banners'] = this.themeJson.banners;
        } else {
          finalTheme['banners'] = [];
        }
      } else {
        finalTheme['show_banner'] = true;
        finalTheme['banners'] = [];
      }

      if (typeof finalTheme.title.fontSize != 'string') {
        let result = await this._shopService.publishTemplate(finalTheme, uidToken, this.shopInfo['shopName']);
        if (result['status'] == 'failed' && result['error']['code'] == 'auth/id-token-expired') {
          this._head.signOut();
        }
      }
      this.isLoading = false;
    } catch (error) {
      console.log('eerror ', error);
      this.isLoading = false;
    }
  }

  async converInNumber(finalJson) {

    let data = await _.each(finalJson, async function (item) {
      if (item.hasOwnProperty('fontSize')) {
        item.fontSize = parseInt(item.fontSize, 10);
      }
      return item;
    });
    return data;
  }


  onColorPickerChange(formKey, colorCode: string): void {

    switch (formKey) {
      case 'title':
        this.theme.patchValue({
          'title': {
            'color': colorCode
          }
        });
        break;

      case 'heading':
        this.theme.patchValue({
          'heading': {
            'color': colorCode
          }
        });
        break;

      case 'color-primary':
        this.theme.patchValue({
          'color': {
            'primary': colorCode
          }
        });
        break;

      case 'color-secondary':
        this.theme.patchValue({
          'color': {
            'secondary': colorCode
          }
        });
        break;

      case 'color-third':
        this.theme.patchValue({
          'color': {
            'tertiary': colorCode
          }
        });
        break;

      case 'color-fourth':
        this.theme.patchValue({
          'color': {
            'quaternary': colorCode
          }
        });
        break;

      case 'button-primary-background':
        this.theme.patchValue({
          'button': {
            'primary': {
              'backgroundColor': colorCode
            }
          }
        });
        break;


      case 'button-primary-text':
        this.theme.patchValue({
          'button': {
            'primary': {
              'textColor': colorCode
            }
          }
        });
        break;

      case 'button-secondary-background':
        this.theme.patchValue({
          'button': {
            'secondary': {
              'backgroundColor': colorCode
            }
          }
        });
        break;


      case 'button-secondary-text':
        this.theme.patchValue({
          'button': {
            'secondary': {
              'textColor': colorCode
            }
          }
        });
        break;

      case 'success-text':
        this.theme.patchValue({
          'button': {
            'success': {
              'textColor': colorCode
            }
          }
        });
        break;

      case 'success-background':
        this.theme.patchValue({
          'button': {
            'success': {
              'backgroundColor': colorCode
            }
          }
        });
        break;

      case 'alert-text':
        this.theme.patchValue({
          'button': {
            'alert': {
              'textColor': colorCode
            }
          }
        });
        break;

      case 'alert-background':
        this.theme.patchValue({
          'button': {
            'alert': {
              'backgroundColor': colorCode
            }
          }
        });
        break;

      case 'body':
        this.theme.patchValue({
          'body': {
            'color': colorCode
          }
        });
        break;

      case 'background':
        this.theme.patchValue({
          'background': {
            'color': colorCode
          }
        });
        break;
    }
  }

  selectedTag(tagValue) {
    this.currentTag = tagValue;
  }

  onLogoSelected(event: any) {
    this.isLoading = true;
    const file = event.target.files[0];
    var n = Date.now();
    if (file.type == 'image/jpeg' || file.type == 'image/png') {
      if (file.size < this.maximumUploadSize) {
        this.logoChangedEvent = event;
        const filePath = `ShopiMobNotifications/${n}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(`ShopiMobNotifications/${n}`, file);
        task.snapshotChanges()
          .pipe(
            finalize(() => {
              this.logodownloadURL = fileRef.getDownloadURL();
              this.logodownloadURL.subscribe(url => {
                if (url) {
                  this.logo = url;
                  console.log('thislogo ', this.logo);
                  this.isLoading = false;
                }
              });
            })
          )
          .subscribe(url => {
            if (url) {
              console.log('URL ', url);
            }
          });
      } else {
        alert('Please select less then 1mb file!');
        this.isLoading = false;
      }
    } else {
      alert('Invalid file format!');
      this.isLoading = false;
    }
  }

  deleteLogo() {
    this.logo = '';
  }

  resetForm(form) {
    if (confirm('Are you sure want to reset the theme?')) {
      form.reset();
      this.logo = '';
      this.setForm();
    }
  }
}

