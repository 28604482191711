// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  config : {
   apiKey: "AIzaSyADItwopBxbWUrU13tDEnYEMLyCCk3Qp5Q",
   authDomain: "shopimob-dev.firebaseapp.com",
   databaseURL: "https://shopimob-dev.firebaseio.com",
   projectId: "shopimob-dev",
   storageBucket: "shopimob-dev.appspot.com",
   messagingSenderId: "497451282546",
   appId: "1:497451282546:web:7091d983c21f41eb32d2eb",
   measurementId: "G-778QTJ413E"
 },
 getTemplateUrl:'https://us-central1-shopimob-dev.cloudfunctions.net/getTemplate',
 publishTemplateUrl:'https://us-central1-shopimob-dev.cloudfunctions.net/publishedTemplate'
 };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
