
<div id="bg"></div>
<!--Header start here-->
<div class="container-fluid header-bg">
    <div class="container-small">
        <div class="row">
            <div class="col-3">
             <div class="logo"><img src="assets/images/logo.png" /> </div>
            </div>
            <div class="col-9 text-right">
                <div class="menu-links"><a target="_blank" href="/documents"><i class="fa fa-question-circle" aria-hidden="true"></i>   Help</a></div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="container-small">
    <div class="d-flex  flex-md-row align-items-center  px-md-4 ">
      <h1 class="my-0 mr-md-auto font-weight-normal"><img src="assets/images/logo.png" /></h1> -->
      <!-- <a class="btn btn-menu active" routerLink="/login">Login</a>
      <a class="btn btn-menu" routerLink="/documents">Documentation</a> -->
    <!-- </div>
  </div> -->
  <!--Header end here-->
<!--Body start here-->
<div class="container-small">
    <div class="page-form ">
        <div class="row">
            <div class="col-md-5 text-center left-block">
                <div class="img-intro text-center"><img src="assets/images/img-intro.png" class="img-fluid  " /></div>
            </div>
            <div class="col-md-7 text-center right-block">
                <form class="form" ngNativeValidate>
                    <div class="header-content">
                        <h2>Log In</h2>
                    </div>
                    <div class="body-content">
                        <div class="alert alert-danger alert-dismissible fade show mt-4 mb-4" role="alert" *ngIf="errorMsg != ''">
                            {{errorMsg}} 
                            <!-- <button type="button" class="close"
                                data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button> -->
                        </div>

                        <div class="form-group mb-3">
                            <div class="input-icon left"><i class="fa fa-user"></i><input type="text"
                                    placeholder="Store Name" name="storename" #storeName value="{{storename}}" class="form-control" [readonly]="storename !=''" required>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <div class="input-icon left"><i class="fa fa-user"></i><input type="text"
                                    placeholder="Username or Email" name="username" #userEmail class="form-control" required>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <div class="input-icon left"><i class="fa fa-lock"></i>
                                 <i class="fa fa-eye right" (click)="password()"></i>
                                    <input [type]="show ? 'text' : 'password'" #userPassword placeholder="Password"
                                    name="password" class="form-control" required>
                            </div>
                        </div>
                        <!-- <div class="form-group pull-left pt-1 pb-3">
                            <div class="checkbox-list"><label><input type="checkbox">&nbsp;
                                    Remember me</label></div>
                        </div> -->
                        <div class="form-group pull-right pt-1 pb-3">
                            <div class="forget-password"><a href='javascript:void(forgot-password)' routerLink="/forgot-password" class='link-muted'>Forgot
                                    Password?</a></div>
                        </div>
                        <div class="clearfix"></div>
                        <a type="submit" class="btn btn-smob" (click)="signIn(userEmail.value,userPassword.value,storeName.value)">LOGIN</a>


                        <!-- <p class="text-center pt-5 mb-0">Don't have an account? <a class="btn-register"
                               routerLink="/signup">Signup</a></p> -->
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!--Footer start here-->
<footer class="footer  pt-5 pb-2  text-center">
    <div class="container-small">
      <p> &copy; shopimob 2020, All Rights Reserved</p>
    </div>
  </footer>
  <!--Footer end here-->

<div class="loading" *ngIf="isLoading == true">Loading</div>
<!--Body end here-->