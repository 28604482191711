import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { auth } from 'firebase/app';
import { DataservicesService } from '../dataservices.service'
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

@Component({
  selector: 'app-app-selection',
  templateUrl: './app-selection.component.html',
  styleUrls: ['./app-selection.component.css']
})
export class AppSelectionComponent implements OnInit {
  shopInfo;
  constructor(public afAuth: AngularFireAuth, public afs: AngularFirestore, public router: Router, private _shopService: DataservicesService) { }

  ngOnInit(): void {
    this.shopInfo = JSON.parse(sessionStorage.getItem('shopInfo'));
  }
  async register(form) {   
    let data = form.value['appType'];
    let details = await this._shopService.updateShops('shops',this.shopInfo['shop'],data);
    let storeExist = await this._shopService.checkStoreName('shops', this.shopInfo['shop'], this.shopInfo['userId']);
    sessionStorage.setItem('shopInfo', JSON.stringify(storeExist[0]));
    this.router.navigate(['dashboard']);
  }

}
