<form class="form" #login="ngForm" (ngSubmit)="register(login)" ngNativeValidate>
    <div class="header-content">
        <h2>Select App type</h2>
        <div class="form-group mb-3">
            <div class="input-icon left">
            
            <input type="radio" ngModel  name="appType" value="Android">
            <label for="male">Android</label><br>
            <input type="radio" ngModel  name="appType" value="Ios">
            <label for="female">Ios</label><br>
            <input type="radio" ngModel  name="appType" value="Both">
            <label for="other">Both</label> 
        </div>
        </div>
    </div>
    <button type="submit" class="btn btn-smob" >Submit</button>
    </form>