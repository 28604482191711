import { Component, OnInit } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { DataservicesService } from '../dataservices.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isLoading: boolean = false;
  userData;
  userInfo;
  errorMsg: string = '';
  show: boolean;
  storename: string;

  constructor(public afAuth: AngularFireAuth, public router: Router, private location: LocationStrategy, private _service: DataservicesService) {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => { history.pushState(null, null, window.location.href) });
  }

  ngOnInit(): void {
    this.show = false;
    var referrer = document.referrer;
    if (referrer) {
      var ref = referrer.split("&");
      var newref = ref[4].split("=");
      var shopname = newref[1];
      sessionStorage.setItem('shopname', shopname);
      this.storename = sessionStorage.getItem('shopname');
    } else {
      this.storename = '';
    }
  }

  // Sign in with email/password
  signIn(email, password, storename) {
    this.isLoading = true;
    if (storename) {
      return this.afAuth.signInWithEmailAndPassword(email, password)
        .then(async (result) => {
          this.userData = result.user;
          if (result.user) {
            let storeExist = await this._service.checkStoreName('shops', storename, this.userData['uid']);
           
            if (storeExist.length > 0) {
              sessionStorage.setItem('userInfo', JSON.stringify(this.userData));
              sessionStorage.setItem('shopInfo', JSON.stringify(storeExist[0]));
              if(storeExist[0]['appType'] == undefined){
                this.router.navigate(['app-type']);
              }else{
              this.router.navigate(['dashboard']);
              }
            } else {
              this.errorMsg = 'No store exist for this user! Please check store name & email combination & try again!'
            }
          }
          this.isLoading = false;
        }).catch((error) => {
          this.errorMsg = error.message;
          this.isLoading = false;
        })
    } else {
      this.errorMsg = 'Store Name is empty!';
      this.isLoading = false;
    }
  }

  // click event function toggle
  password() {
    this.show = !this.show;
  }
}
