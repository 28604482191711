import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { DataservicesService } from '../dataservices.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AngularFireStorage } from "@angular/fire/storage";
import { map, finalize, tap } from "rxjs/operators";


@Component({
  selector: 'app-edit-notifications',
  templateUrl: './edit-notifications.component.html',
  styleUrls: ['./edit-notifications.component.css']
})

export class EditNotificationsComponent implements OnInit {

  details: any;
  currentURLId: number;
  routeSub;
  isLoading: boolean = false;
  userInfo: any;
  notificationResult: any;
  all_users: any;
  sent_to = [];
  dropdownList = [];
  selectedItems = [];
  schedule_date: string;
  dropdownSettings: IDropdownSettings;
  schedule_time = new Date(new Date().setHours(0, 0, 0, 0));
  weekday: string = 'Sunday';
  selectedFile: File = null;
  profilePic: string = '';
  downloadURL;
  maximumUploadSize: Number = 1000000;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  fileToReturn;
  images: string = '';
  notification_image;
  requiredClass: string = 'validField';
  published: boolean = false;
  interval: string = 'once';
  showdate: boolean = true;
  showweekday: boolean = false;
  shopDetails: any;
  shop:any;



  constructor(public router: Router, private route: ActivatedRoute, private _shopService: DataservicesService, private storage: AngularFireStorage) {
    this.isLoading = true;
    this.shopDetails = JSON.parse(sessionStorage.getItem('shopInfo'));
    this.routeSub = this.route.params.subscribe(params => {
      this.currentURLId = params['id'];
      this.editNotification(this.currentURLId);
    });
  }

  async ngOnInit() {

    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
   
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'email',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 6,
      allowSearchFilter: true
    }

    this.all_users = await this._shopService.userList('users', 'appUser');
  }


  onItemSelect(item: any) {
    console.log('bb ', this.sent_to.length);
    (this.sent_to.length > 0) ? this.requiredClass = 'validField' : this.requiredClass = 'invalidField';
  }


  onItemDeSelect(item: any) {
    console.log('aa ',);
    //console.log(Object.keys(item).length);
    (this.sent_to.length > 0) ? this.requiredClass = 'validField' : this.requiredClass = 'invalidField';
  }

  onSelectAll(items: any) {
    console.log(items.length);
    (items.length > 0) ? this.requiredClass = 'validField' : this.requiredClass = 'invalidField';
  }

  onDeSelectAll(items: any) {

    (items.length > 0) ? this.requiredClass = 'validField' : this.requiredClass = 'invalidField';
  }

  async editNotification(docid) {
    let editResult = await this._shopService.editNotification('ShopiMob', 'notifications',this.shopDetails.shop , docid);
    if (editResult.length > 0) {
      this.notificationResult = editResult[0];
      //console.log('this.notificationResult ', this.notificationResult);
      this.sent_to = this.notificationResult['sent_to'];
      this.images = this.notificationResult['details']['image'];
      this.published = this.notificationResult['published'];
      this.interval = this.notificationResult['schedule']['interval_type'];
      this.weekday = this.notificationResult['schedule']['day'];

      this.selectedInterval(this.interval);

      if (this.notificationResult['schedule']['time'] != undefined) {
        let schedule_seconds = this.notificationResult['schedule']['time']['seconds'];
        let time = new Date(0);
        time.setUTCSeconds(schedule_seconds);
        this.notificationResult['schedule']['time'] = time;
      } else {
        this.notificationResult['time'] = this.schedule_time
      }

      if (this.notificationResult['schedule']['date'] != undefined) {
        var utcSeconds = this.notificationResult['schedule']['date']['seconds'];
        var date = new Date(0);
        date.setUTCSeconds(utcSeconds);
        this.notificationResult['schedule']['date'] = date;
      }
    }
    this.isLoading = false;
  }

  async updateNotification(form) {
    if (form.valid) {
      this.isLoading = true;

      if (this.images != '') {
        this.notification_image = this.images;
      } else {
        this.notification_image = '';
      }


      let inputJson = {
        details: {
          subject: form.value['subject'],
          description: form.value['description'],
          image: this.notification_image
        },
        schedule: {},
      }

      let newSent = [];
      form.value['sent_to'].forEach(function (value) {
        newSent.push(value['id']);
      });

      if (this.interval == 'daily') {

        inputJson['schedule']['time'] = form.value['schedule_time'];
        inputJson['schedule']['interval_type'] = this.interval;
      }

      if (this.interval == 'weekly') {
        inputJson['schedule']['day'] = form.value['weekday'];
        inputJson['schedule']['time'] = form.value['schedule_time'];
        inputJson['schedule']['interval_type'] = this.interval;
      }

      if (this.interval == 'monthly' || this.interval == 'once') {
        inputJson['schedule']['date'] = form.value['schedule_date'];
        inputJson['schedule']['time'] = form.value['schedule_time'];
        inputJson['schedule']['interval_type'] = this.interval;
      }

      inputJson['details']['image'] = this.notification_image;
      inputJson['type'] = 'Custom';
      inputJson['status'] = 'Pending';
      inputJson['published'] = this.published;
      inputJson['sent_to'] = form.value['sent_to'];
      inputJson['modified_by'] = this.userInfo['uid'];
      inputJson['modified_date'] = new Date().toISOString();

      let allDeviceTokens = await this._shopService.userDeviceToken('ShopiMob', 'deviceTokens', 'shopify', newSent);

      if (allDeviceTokens.length > 0) {
        let tokens = [];
        allDeviceTokens.forEach(function (value) {
          tokens.push(value['token']['token']);
        });

        inputJson['user_device_tokens'] = tokens;
      }else{
        inputJson['user_device_tokens']=[];
      }

      let result = await this._shopService.updateNotification('ShopiMob', 'notifications', this.shopDetails.shop, inputJson, this.currentURLId);
      this.isLoading = false;
      this.router.navigate(['notifications']);

    } else {
      this.requiredClass = 'invalidField';
      this.isLoading = false;
    }
  }

  publishNotification(data) {
    this.published = true;
    this.updateNotification(data);
  }


  async deleteNotification(deleteId) {
    if (confirm("Are you sure want to delete this notification?")) {
      this.isLoading = true;
      let result = await this._shopService.deleteNotification('ShopiMob', 'notifications', this.shopDetails.shop, this.currentURLId);
      this.isLoading = false;
      this.router.navigate(['notifications']);
    }
  }


  onFileSelected(event: any) {
    this.isLoading = true;
    const file = event.target.files[0];
    var n = Date.now();
    if (file.type == 'image/jpeg' || file.type == 'image/png') {
      if (file.size < this.maximumUploadSize) {
        this.imageChangedEvent = event;
        const filePath = `ShopiMobNotifications/${n}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(`ShopiMobNotifications/${n}`, file);
        task.snapshotChanges()
          .pipe(
            finalize(() => {
              this.downloadURL = fileRef.getDownloadURL();
              this.downloadURL.subscribe(url => {
                if (url) {
                  this.images = url;
                  this.notificationResult['details']['image'] = url;
                  //console.log('this.profilePic ', this.images);
                  this.isLoading = false;
                }
              });
            })
          )
          .subscribe(url => {
            if (url) {
              console.log('URL ', url);
            }
          });
      } else {
        alert('Please select less then 1mb file!');
        this.isLoading = false;
      }
    } else {
      alert('Invalid file format!');
      this.isLoading = false;
    }
  }

  selectedInterval(item) {

    this.interval = item;

    switch (this.interval) {

      case 'daily':
        this.showdate = false;
        this.showweekday = false;
        // this.selectMode = 'single';
        break;
      case 'weekly':
        this.showdate = false;
        this.showweekday = true;
        // this.selectMode = 'range';
        break;
      case 'monthly':
        this.showdate = true;
        this.showweekday = false;
        // this.selectMode = 'range';
        break;
    }
  }

}
