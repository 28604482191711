import { Component, OnInit } from '@angular/core';
import { DataservicesService } from '../dataservices.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  details: any = [];
  isLoading: boolean = false;
  userInfo: any;
  all_users: any;
  shopDetails: any;
  noNotification: boolean = false;
  pagination: boolean = false;
  //Data object for listing items
  tableData: any[] = [];

  //Save first document in snapshot of items received
  firstInResponse: any = [];

  //Save last document in snapshot of items received
  lastInResponse: any = [];

  //Keep the array of first document of previous pages
  prev_strt_at: any = [];

  //Maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;

  //Disable next and prev buttons
  disable_next: boolean = false;
  disable_prev: boolean = false;
  shopInfo;


  constructor(private _shopService: DataservicesService, private firestore: AngularFirestore, private afAuth: AngularFireAuth) { }

  async ngOnInit() {
    this.isLoading = true;
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.shopDetails = JSON.parse(sessionStorage.getItem('shopInfo'));
    this.getNotifications();
  }


  async getNotifications() {
    this.isLoading = true;
    this.afAuth.onAuthStateChanged(async (user) => {
      if (user != null) {
        this.firestore.collection('ShopiMob/notifications/' + this.shopDetails.shop, ref => ref.where('created_by', '==', this.userInfo['uid'])
          .limit(5)
          //.orderBy('created_date', 'desc')
        ).snapshotChanges()
          .subscribe(response => {
            if (!response.length) {
              this.noNotification = true;
              this.pagination = false;
              this.isLoading = false;
              return false;
            }

            if (response.length < 5) {
              this.firstInResponse = response[0].payload.doc;
              this.lastInResponse = response[response.length - 1].payload.doc;

              this.tableData = [];
              for (let item of response) {
                let result = item.payload.doc.data();
                result['id'] = item.payload.doc.id;
                this.tableData.push(result);
              }
              this.isLoading = false;
              this.pagination = false;
              return;
            }

            this.firstInResponse = response[0].payload.doc;
            this.lastInResponse = response[response.length - 1].payload.doc;

            this.tableData = [];
            for (let item of response) {
              let result = item.payload.doc.data();
              result['id'] = item.payload.doc.id;
              this.tableData.push(result);
            }

            // initialize values
            this.pagination = true;
            this.prev_strt_at = [];
            this.pagination_clicked_count = 0;
            this.disable_next = false;
            this.disable_prev = false;

            //Push first item to use for Previous action
            this.push_prev_startAt(this.firstInResponse);
            this.isLoading = false;

          })
      }
    })
  }


  async nextPage() {
    this.isLoading = true;
    this.disable_next = true;
    this.firestore.collection('ShopiMob/notifications/' + this.shopDetails.shop, ref => ref.where('created_by', '==', this.userInfo['uid'])
      .limit(5)
      //.orderBy('created_date', 'desc')
      .startAfter(this.lastInResponse)
    ).get()
      .subscribe(response => {
        if (!response.docs.length) {
          this.disable_next = true;
          this.isLoading = false;
          return;
        }

        if (response.docs.length < 5) {
          this.disable_next = true;
          this.firstInResponse = response.docs[0];
          this.lastInResponse = response.docs[response.docs.length - 1];
          this.tableData = [];
          for (let item of response.docs) {
            let result = item.data();
            result['id'] = item.id;
            this.tableData.push(result);
          }

          this.pagination_clicked_count++;
          this.push_prev_startAt(this.firstInResponse);
          this.isLoading = false;
          return;
        }

        this.firstInResponse = response.docs[0];

        this.lastInResponse = response.docs[response.docs.length - 1];
        this.tableData = [];
        for (let item of response.docs) {
          let result = item.data();
          result['id'] = item.id;
          this.tableData.push(result);
        }

        this.pagination_clicked_count++;

        this.push_prev_startAt(this.firstInResponse);

        this.disable_next = false;
        this.isLoading = false;
      })
  }


  async prevPage() {
    this.isLoading = true;
    this.disable_prev = true;
    this.firestore.collection('ShopiMob/notifications/' + this.shopDetails.shop, ref => ref.where('created_by', '==', this.userInfo['uid'])
      //.orderBy('created_date', 'desc')
      .startAt(this.get_prev_startAt())
      .endBefore(this.firstInResponse)
      .limit(5)
    ).get()
      .subscribe(response => {
        this.firstInResponse = response.docs[0];
        this.lastInResponse = response.docs[response.docs.length - 1];

        this.tableData = [];
        for (let item of response.docs) {
          let result = item.data();
          result['id'] = item.id;
          this.tableData.push(result);
        }

        //Maintaing page no.
        this.pagination_clicked_count--;
        //Pop not required value in array
        this.pop_prev_startAt(this.firstInResponse);

        //Enable buttons again
        this.disable_prev = false;
        this.disable_next = false;

        this.isLoading = false;
      })
  }


  push_prev_startAt(prev_first_doc) {
    this.prev_strt_at.push(prev_first_doc);
  }
  // remove non required document 
  pop_prev_startAt(prev_first_doc) {
    this.prev_strt_at.forEach(element => {
      if (prev_first_doc.data().id == element.data().id) {
        element = null;
      }
    });
  }

  // return the Doc rem where previous page will startAt
  get_prev_startAt() {
    if (this.prev_strt_at.length > (this.pagination_clicked_count + 1)) {
      this.prev_strt_at.splice(this.prev_strt_at.length - 2, this.prev_strt_at.length - 1);
    }
    return this.prev_strt_at[this.pagination_clicked_count - 1];
  }


  scheduleTime(SECONDS) {
    var timestamp = SECONDS * 1000;
    let date = new Date(timestamp).toLocaleTimeString();
    return date;
  }

}
