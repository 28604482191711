
  <!-- top menu start-->
  <nav class="navbar navbar-expand-lg bg-white shadow-sm " id="topbar">
      <div class="container-fluid">

          <button type="button" id="sidebarCollapse" class="btn btn-light  ">
              <i class="fa fa-bars"></i>
          </button>
          <a class="navbar-brand ml-4 pt-0 pb-0 " href="javascript:void(0)"><img src="assets/images/logo-sm.png" /></a>
          <ul class="nav navbar-nav ml-auto navbar-top-links " id="profile_dropdown">
              <li class="nav-item dropdown topbar-user" id="profile"><a data-hover="dropdown" href="javascript:void(0)"
                      class="dropdown-toggle nav-link pr-0">
                      <img src="{{userImage}}"  onerror="this.src='assets/images/userpic.jpg'" alt=""
                          class="img-responsive img-circle" />&nbsp;<span class="hidden-xs">{{userInfo?.displayName}} ({{shopInfo?.shopName}})
                        </span>&nbsp;<span class="caret"></span></a>
                  <ul class="dropdown-menu dropdown-user pull-right shadow-sm">
                      <li><a href="#" routerLink="profile"><i class="fa fa-user"></i> My Profile</a></li>

                      <li><a href="#" routerLink="plan"><i class="fa fa-tasks"></i> Plan</a></li>

                      <li><a href="javascript:void('logout')" (click)="signOut()"><i class="fa fa-sign-out"></i>Log Out</a></li>
                  </ul>
              </li>
          </ul>
      </div>
  </nav>
  <!-- top menu end-->
