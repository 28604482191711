import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { DataservicesService } from '../dataservices.service'
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';

declare var $: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  userInfo: {};
  errorMsg: string = '';
  successMsg: string = '';
  isLoading: boolean = false;
  date_of_birth;
  details: any = {};
  email;
  userId;

  selectedFile: File = null;
  profilePic: string = '';
  downloadURL;
  maximumUploadSize: Number = 5000000;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  fileToReturn;
  isLoadingImage: boolean = false;

  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  constructor(public afAuth: AngularFireAuth, private _shopService: DataservicesService) { }


  ngOnInit(): void {
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    if (this.userInfo != null) {
      this.email = this.userInfo['email'];
      this.userId = this.userInfo['uid'];
    }
    this.getProfileDetails();
  }

  async getProfileDetails() {
    this.isLoading = true;
    let result = await this._shopService.getUserDetails('users', this.userId);
    let utcSeconds = 0;
    if (result.length > 0) {
      this.details = result[0];
      if (this.details.hasOwnProperty("date_of_birth")) {
        utcSeconds = this.details['date_of_birth']['seconds'];
      } else {
        utcSeconds = 0;
      }

      var d = new Date(0);
      d.setUTCSeconds(utcSeconds);
      this.details['date_of_birth'] = d;
    }
    this.isLoading = false;
  }

  async addProfileDetails(form) {
    if (form.valid) {
      this.isLoading = true;
      let profileDetails = form.value;
      let result = await this._shopService.updateUserDetails('users', this.userId, profileDetails);
      this.getProfileDetails();
      this.isLoading = false;
    }
  }


  async passwordChange(new_password, confirm_passwpord) {
    this.isLoading = true;
    if (new_password != '' || confirm_passwpord != '') {
      if (new_password != confirm_passwpord) {

        this.errorMsg = 'Password not matched!';
        this.isLoading = false;

      } else {

        let result = await (await this.afAuth.currentUser).updatePassword(new_password).then(async function (data) {
          return 'success!';
        }).catch(async function (error) {
          return error.message;
        });

        if (result != 'success!') {
          this.errorMsg = result;
          this.isLoading = false;
        } else {
          this.successMsg = 'Password updated successfully!';
          this.isLoading = false;
        }
      }
    } else {
      this.errorMsg = 'Password are required!';
      this.isLoading = false;
    }
  }



  fileChangeEvent(event: any): void {
    const file = event.target.files[0];
    if (file.type == 'image/jpeg' || file.type == 'image/png') {
      this.isLoadingImage = true;
      if (file.size < this.maximumUploadSize) {
        this.imageChangedEvent = event;
        $("#ImageModal").modal('show');
      } else {
        alert('Please select less then 5mb file!');
      }
    } else {
      alert('Invalid file format!');
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.fileToReturn = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,
    )
  }

  imageLoaded() {
    console.log('loaded ', event);
    // show cropper
  }
  cropperReady() {
    this.isLoadingImage = false;
    // cropper ready
  }
  loadImageFailed() {
    this.isLoadingImage = false;
    console.log('failed ', event);
    // show message
  }

  async cropIt() {
    this.isLoading = true;
    let url = await this._shopService.uploadImage(this.fileToReturn, this.userId);
    if (url != '') {
      this.details['profile_pic'] = url;
      $("#ImageModal").modal('hide');
      this.isLoading = false;
    }
  }


  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }


}
